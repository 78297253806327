import React, { useState, useEffect, useMemo } from "react";
import { entityMap } from "../utils/mappings";
import axios from "axios";
import Pagination from "../components/Pagination";
import LandModal from "../components/landModal";

const LandLookup = () => {
  const [landData, setLandData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [landsPerPage] = useState(30);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    environment: "",
    landSize: "",
    houseSize: "",
    treeDensity: "",
    permissions: "",
    housePermissions: "",
    guild: "",
  });
  const [industryFilters, setIndustryFilters] = useState([]);
  const [selectedLand, setSelectedLand] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const tierOrder = ["Boosts", "Untiered","T1", "T2", "T3", "T4", "Other"];
  const [handles, setGuildHandles] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const landResponse = await axios.get("https://api.pixelore.wiki/api/lands");
        const guildResponse = await axios.get("https://api.pixelore.wiki/api/guilds");

        // Get guild handles from guild data
        const guildHandles = guildResponse.data.map((guild) => guild.handle);

        // Get unique guilds from land data
        const landGuilds = new Set(landResponse.data.map((land) => land.guild));

        // Find common guild handles
        const commonGuilds = guildHandles.filter((handle) => landGuilds.has(handle));
        setGuildHandles(commonGuilds); // Update state with common guilds

        // Set landData if you still need it for other purposes
        setLandData(landResponse.data);
      } catch (error) {
        console.log("Issue fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (e) => setSearchQuery(e.target.value.toLowerCase());

  //Handles when each individual filter (except industries) are changed
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Function to get industry details (tier and image)
  const getIndustryDetails = (industryName) => {
    for (const tier in entityMap) {
      if (entityMap[tier][industryName]) {
        return { tier, ...entityMap[tier][industryName] };
      }
    }
    return { name: "Unknown", image: "/images/industries/default.png", tier: "Unknown" };
  };

  const categories = Object.keys(entityMap);

  const getIndustriesByCategory = (category) => {
    const industries = Object.values(entityMap[category]).map((industry) => industry.name);
    return [...new Set(industries)]; // Convert Set back to an array to return unique industry names
  };

  const handleCategoryChange = (category, index) => {
    setIndustryFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[index] = { ...updatedFilters[index], category }; // Update the category for the specific filter
      return updatedFilters;
    });
  };

  // Function to update the industry of a specific filter
  const handleIndustryChange = (industry, index) => {
    setIndustryFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[index] = { ...updatedFilters[index], industry }; // Update the industry for the specific filter
      return updatedFilters;
    });
  };

  // Function to add a new filter
  const addIndustryFilter = () => {
    setIndustryFilters((prevFilters) => [
      ...prevFilters,
      { category: "", industry: "", amount: "" }, // Add a new filter object
    ]);
  };

  // Function to update an existing filter (industry and category)
  const updateIndustryFilter = (index, key, value) => {
    setIndustryFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];

      if (key === "industry") {
        const firstSpaceIndex = value.indexOf(" "); // Find the first space
        const category = value.substring(0, firstSpaceIndex); // Extract the tier up to the first space
        const industryName = value.substring(firstSpaceIndex + 1); // Extract everything after the first space

        updatedFilters[index] = { ...updatedFilters[index], industry: industryName, category: category };
      } else {
        updatedFilters[index] = { ...updatedFilters[index], [key]: value };
      }

      return updatedFilters;
    });
  };

  // Function to remove a filter
  const removeIndustryFilter = (index) => {
    const updatedFilters = industryFilters.filter((_, i) => i !== index);
    setIndustryFilters(updatedFilters);
  };

  const getUniqueValues = (data, key) => {
    const uniqueValues = new Set();

    data.forEach((item) => {
      const value = item[key];
      if (Array.isArray(value)) {
        // If value is an array, add each unique item separately
        value.forEach((v) => uniqueValues.add(v));
      } else if (typeof value === "object" && value !== null) {
        // If value is an object, iterate and add each unique entry
        Object.values(value).forEach((v) => uniqueValues.add(v));
      } else if (value !== undefined && value !== null && value !== "") {
        uniqueValues.add(value);
      }
    });

    return Array.from(uniqueValues);
  };

  const groupIndustries = (industries) => {
    const grouped = {};
    for (const industry of Object.entries(industries)) {
      const [name, count] = industry;
      const { tier, name: industryName, image } = getIndustryDetails(name);
      if (!grouped[tier]) {
        grouped[tier] = {};
      }
      if (!grouped[tier][industryName]) {
        grouped[tier][industryName] = { count: 0, image };
      }
      grouped[tier][industryName].count += count;
    }
    return grouped;
  };

  const uniqueLandSizes = getUniqueValues(landData, "landSize");
  const uniqueHouseSizes = getUniqueValues(landData, "houseSize");
  const uniqueEnvironments = getUniqueValues(landData, "environment");
  const uniqueLandPermissions = getUniqueValues(landData, "permissions");
  const uniqueHousePermissions = getUniqueValues(landData, "housePermissions");
  const uniqueTreeDensities = getUniqueValues(landData, "treeDensity");

  // Modified filteredLands to handle industryFilters
  const filteredLands = useMemo(() => {
    // Safety check for landData
    if (!Array.isArray(landData)) return [];
    return landData
      .filter((land) => land.landNum && (!searchQuery || land.landNum.toString().startsWith(searchQuery)))
      .filter((land) => {
        // Apply all non-industry filters
        const meetsBasicFilters = Object.entries(filters).every(([key, value]) => {
          if (!value) return true; // Skip filter if no value is selected
          const landValue = land[key];

          // Handle permissions as arrays
          if (key === "permissions" || key === "housePermissions") {
            return Array.isArray(landValue) && landValue.includes(value);
          }

          return typeof landValue === "string" && landValue.toLowerCase() === value.toLowerCase();
        });

        // Check industry-specific filters
        const meetsIndustryFilters = industryFilters.every((filter) => {
          const { category, industry, amount } = filter; // Include amount

          if (!category || !industry) return true;

          // Get all entity keys that belong to the selected tier
          const tierEntities = Object.keys(entityMap[category] || {}).filter((key) => {
            return entityMap[category][key].name.toLowerCase() === industry.toLowerCase();
          });

          // Check if any of the entities in the tier exist in land.industries
          const landIndustries = land.industries || [];
          const landHouseIndustries = land.houseIndustries || []; // Add houseIndustries check

          // Ensure the landIndustries is an array and perform the check
          const meetsIndustryCriteria = (landIndustries) => {
            return (
              Array.isArray(landIndustries) &&
              landIndustries.some((landIndustry) => {
                // Each landIndustry is an object, check if any of the tier entities exist in it
                return tierEntities.some((tierEntity) => {
                  const entityAmount = landIndustry[tierEntity] || 0; // Get the amount
                  return entityAmount > 0 && (!amount || entityAmount >= amount); // Check if it meets the amount filter
                });
              })
            );
          };

          // Check against both industries and houseIndustries
          return meetsIndustryCriteria(landIndustries) || meetsIndustryCriteria(landHouseIndustries);
        });

        return meetsBasicFilters && meetsIndustryFilters;
      })
      .sort((a, b) => a.landNum - b.landNum);
  }, [landData, searchQuery, filters, industryFilters]);

  //modal items
  const handleLandClick = (land) => {
    const index = filteredLands.findIndex((i) => i.landNum === land.landNum); // Find the index of the clicked item
    setCurrentIndex(index);
    setSelectedLand(land);
  };

  const handleCloseModal = () => {
    setSelectedLand(null);
    setCurrentIndex(null);
  };

  // Navigates to the next item
  const goToNextLand = () => {
    if (currentIndex < filteredLands.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setSelectedLand(filteredLands[currentIndex + 1]); // Set next item
    }
  };

  // Navigates to the previous item
  const goToPreviousLand = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setSelectedLand(filteredLands[currentIndex - 1]); // Set previous item
    }
  };

  const indexOfLastLand = currentPage * landsPerPage;
  const indexOfFirstLand = indexOfLastLand - landsPerPage;
  const currentLands = filteredLands.slice(indexOfFirstLand, indexOfLastLand);

  const totalPages = Math.ceil(filteredLands.length / landsPerPage);
  const maxPageNumbers = 5;
  const halfPageRange = Math.floor(maxPageNumbers / 2);

  let startPage = Math.max(1, currentPage - halfPageRange);
  let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

  if (endPage - startPage + 1 < maxPageNumbers) {
    startPage = Math.max(1, endPage - maxPageNumbers + 1);
  }

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [totalPages, currentPage]);

  const clearFilters = () => {
    setIndustryFilters([]); // Clear all industry filters
    setSearchQuery(""); // Reset land number input
    setFilters({ environment: "", landSize: "", houseSize: "", treeDensity: "", permissions: "", housePermissions: "", guild: "" }); // Reset other filters, adjust according to your filter structure
  };

  return (
    <div className="container mx-auto mt-2 p-6 rounded-lg shadow-lg bg-retrodp">
      <h1 className="text-5xl font-heading mb-2 text-center text-retroegg">Land Lookup</h1>
      <div className="bg-retropb p-2 rounded-md mb-1 text-sm border-retrobabypink border-4">
        <h2 className="font-eventHeading text-2xl">Industry Filters</h2>
        <div className="flex space-x-2 mb-1">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Land Num"
            className="text-xs h-8 my-auto border rounded text-retrogray w-20 pl-1"
          />

          {Object.keys(filters).map((filterKey) => {
            let options = [];

            switch (filterKey) {
              case "landSize":
                options = uniqueLandSizes;
                break;
              case "houseSize":
                options = uniqueHouseSizes;
                break;
              case "environment":
                options = uniqueEnvironments;
                break;
              case "permissions":
                options = uniqueLandPermissions;
                break;
              case "housePermissions":
                options = uniqueHousePermissions;
                break;
              case "treeDensity":
                options = uniqueTreeDensities;
                break;
              case "guild":
                options = Array.isArray(handles) ? handles.sort() : []; // Ensure handles is an array and sort alphabetically
                break;
              default:
                break;
            }

            return (
              <select
                key={filterKey}
                name={filterKey}
                value={filters[filterKey]}
                onChange={handleFilterChange}
                className="p-1 border mx-1 my-1 rounded text-xs text-retrogray"
              >
                <option value="">All {filterKey.charAt(0).toUpperCase() + filterKey.slice(1)}</option>
                {options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            );
          })}

          <button onClick={addIndustryFilter} className="ml-2 px-1 py-1 bg-retrobg text-white rounded">
            + Industry/Boost
          </button>
          <button onClick={clearFilters} className="ml-2 px-2 py-1 bg-gray-600 text-white rounded">
            Clear Filters
          </button>
          <p className="text-xs text-gray-400 my-auto">({filteredLands.length} Lands Filtered)</p>
        </div>

        {/* Mapping over industryFilters to display them */}
        {industryFilters.map((filter, index) => {
          return (
            <div key={index} className="industry-filter">
              {/* Category Dropdown */}
              <select
                onChange={(e) => handleCategoryChange(e.target.value, index)} // Pass the index to update the correct filter
                value={filter.category || ""}
                className="rounded text-retropb text-xs mx-1"
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>

              {/* Industry Dropdown - Only displays industries for the selected category */}
              {filter.category && (
                <select
                  onChange={(e) => handleIndustryChange(e.target.value, index)} // Pass the index to update the correct filter
                  value={filter.industry || ""}
                  className="rounded text-retropb text-xs mx-1"
                >
                  <option value="">Select Industry</option>
                  {getIndustriesByCategory(filter.category).map((industry, index) => (
                    <option key={index} value={industry}>
                      {industry}
                    </option>
                  ))}
                </select>
              )}

              {/* Input for updating the amount */}
              <input
                type="number"
                value={filter.amount}
                onChange={(e) => updateIndustryFilter(index, "amount", e.target.value)}
                placeholder="Amount"
                className="text-sm text-retropb rounded px-2 w-20"
              />

              {/* Remove button */}
              <button onClick={() => removeIndustryFilter(index)} className="bg-red-500 text-white p-1 mx-1 rounded-md text-xs">
                X
              </button>
            </div>
          );
        })}
      </div>

      {/* Table Rendering */}
      <div className="space-y-4 max-h-[52vh] overflow-auto text-sm">
        <table className="min-w-full tablelb2">
          <thead>
            <tr>
              <th>Num</th>
              <th>Env</th>
              <th>Land</th>
              <th>House</th>
              <th>Trees</th>
              <th>Land Industries</th>
              <th>House Industries</th>
              <th>Guild</th>
            </tr>
          </thead>
          <tbody>
            {currentLands.map((land) => {
              const industriesGrouped = groupIndustries(land.industries[0] || {}); // Grouping industries for current land
              const houseIndustriesGrouped = groupIndustries(land.houseIndustries[0] || {}); // Grouping industries for current land
              return (
                <tr key={land.landNum}>
                  <td className="hover:text-retropink cursor-pointer" onClick={() => handleLandClick(land)}>
                    <div className="shadow shadow-retropink shadow-2 rounded-lg mb-1 p-1">{land.landNum}</div>
                  </td>
                  <td>
                    {land.environment === "Water" ? (
                      <div className="shadow shadow-blue-300 shadow-2 rounded-lg mb-1 p-1">{land.environment || "None"}</div>
                    ) : land.environment === "Space" ? (
                      <div className="shadow shadow-purple-600 shadow-2 rounded-lg mb-1 p-1">{land.environment || "None"}</div>
                    ) : (
                      <div className="shadow shadow-green-400 shadow-2 rounded-lg mb-1 p-1">{land.environment || "None"}</div>
                    )}
                  </td>
                  <td>
                    <div className="shadow shadow-retropink shadow-2 rounded-lg mb-1 p-1">{land.landSize}</div>
                  </td>
                  <td>
                    <div className="shadow shadow-retropink shadow-2 rounded-lg mb-1 p-1">{land.houseSize}</div>
                  </td>

                  <td>
                    <div className="shadow shadow-retropink shadow-2 rounded-lg mb-1 p-1">{land.treeDensity || "None"}</div>
                  </td>
                  <td>
                    {Object.entries(industriesGrouped)
                      .sort(([tierA], [tierB]) => {
                        return tierOrder.indexOf(tierA) - tierOrder.indexOf(tierB);
                      })
                      .map(([tier, industries]) => (
                        <div key={tier}>
                          <div className="shadow shadow-retropink shadow-2 rounded-lg mb-1 text-left pl-2">
                            {tier}:{" "}
                            {Object.entries(industries).map(([name, { count, image }]) => (
                              <span key={name}>
                                <img src={image} alt={name} className="inline mx-1" style={{ width: "25px", height: "30px" }} />x {count}{" "}
                              </span>
                            ))}
                          </div>
                        </div>
                      ))}
                  </td>
                  <td>
                    <ul>
                      {Object.entries(houseIndustriesGrouped)
                        .sort(([tierA], [tierB]) => {
                          return tierOrder.indexOf(tierA) - tierOrder.indexOf(tierB);
                        })
                        .map(([tier, industries]) => (
                          <div key={tier}>
                            <div className="shadow shadow-retropink shadow-2 rounded-lg mb-1 text-left pl-2">
                              {tier}:{" "}
                              {Object.entries(industries).map(([name, { count, image }]) => (
                                <span key={name}>
                                  <img src={image} alt={name} className="inline mx-1" style={{ width: "25px", height: "30px" }} /> x {count}{" "}
                                </span>
                              ))}
                            </div>
                          </div>
                        ))}
                    </ul>
                  </td>
                  <td>
                    <div className="shadow shadow-retropink shadow-2 rounded-lg mb-1 p-1">{handles.includes(land.guild) ? land.guild : "None"} </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
      {selectedLand && (
        <LandModal
          land={selectedLand}
          onClose={handleCloseModal}
          goToPreviousLand={goToPreviousLand}
          goToNextLand={goToNextLand}
          currentIndex={currentIndex}
          landLength={filteredLands.length}
        />
      )}
    </div>
  );
};

export default LandLookup;
