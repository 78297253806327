import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUGC } from "../utils/fetchUGCData";
import Select from "react-select";
import ItemModal from "../components/itemModal";
import Pagination from "../components/Pagination";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const UGCLookup = () => {
  const navigate = useNavigate();
  const query = useQuery();

  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null); //modal
  const [currentIndex, setCurrentIndex] = useState(null); //modal index
  const [filtersVisible, setFiltersVisible] = useState(true); //hide filters on page
  const [searchTerm, setSearchTerm] = useState(query.get("search") || ""); //search filter
  const [types, setTypes] = useState([]); //category filter
  const [typeOptions, setTypeOptions] = useState([]); //category filter
  const [selectedTypes, setSelectedTypes] = useState(query.getAll("types") || []); //category filter
  const [selectedCreator, setSelectedCreator] = useState(query.get("creator") || ""); //ugc creator filter
  const [ugcCreators, setUGCCreators] = useState([]); //ugc creator filter
  const [currentPage, setCurrentPage] = useState(parseInt(query.get("page") || "1", 10)); //Pagination
  const itemsPerPage = 50; //Pagination
  const [sortColumn, setSortColumn] = useState("marketPrice"); //Sort Feature
  const [sortOrder, setSortOrder] = useState("desc"); //Sort Feature
  const selectRef = useRef(null); //to clear the category filter properly

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchTerm) params.set("search", searchTerm);
    if (selectedTypes.length > 0) params.delete("types"); // Remove existing types
    selectedTypes.forEach((type) => params.append("types", type));
    if (selectedCreator) params.set("creator", selectedCreator);
    params.set("page", currentPage);

    navigate({ search: params.toString() });
  }, [searchTerm, selectedTypes, selectedCreator, currentPage, navigate]);

  //modal items
  const handleItemClick = (item) => {
    const index = filteredItems.findIndex((i) => i.id === item.id); // Find the index of the clicked item
    setCurrentIndex(index);
    setSelectedItem(item);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setCurrentIndex(null);
  };

  // Navigates to the next item
  const goToNextItem = () => {
    if (currentIndex < filteredItems.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setSelectedItem(filteredItems[currentIndex + 1]); // Set next item
    }
  };

  // Navigates to the previous item
  const goToPreviousItem = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setSelectedItem(filteredItems[currentIndex - 1]); // Set previous item
    }
  };

  //filters
  const handleCreatorChange = (selectedOption) => setSelectedCreator(selectedOption.value);
  const handlePageChange = (newPage) => setCurrentPage(newPage);
  const handleTypeChange = (selectedOptions) => setSelectedTypes(selectedOptions.map((option) => option.value));

  const handleSort = (column) => {
    const order = sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(order);
  };

  useEffect(() => {
    fetchUGC(setItems, setUGCCreators, setTypes);
  }, []);

  useEffect(() => {
    const options = types.map((type) => ({ value: type, label: type }));
    setTypeOptions([...options]);
  }, [types]);

  const filteredItems = useMemo(() => {
    return items
      .filter((item) => item.name && (item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.id.toLowerCase().includes(searchTerm.toLowerCase())))
      .filter(
        (item) =>
          selectedTypes.length === 0 ||
          selectedTypes.includes("all") ||
          selectedTypes.some((selectedType) => item.type.split(",").some((type) => type.trim() === selectedType))
      )
      .filter((item) => !selectedCreator || item.creatorName === selectedCreator)
      .sort((a, b) => {
        const valueA = a[sortColumn] ?? "";
        const valueB = b[sortColumn] ?? "";

        const isANaOrNull = valueA === "N/A" || valueA === null;
        const isBNaOrNull = valueB === "N/A" || valueB === null;

        if (isANaOrNull && isBNaOrNull) return 0;
        if (isANaOrNull) return 1;
        if (isBNaOrNull) return -1;

        const numA = parseFloat(valueA.toString().replace(/,/g, ""));
        const numB = parseFloat(valueB.toString().replace(/,/g, ""));

        const isANumber = !isNaN(numA);
        const isBNumber = !isNaN(numB);

        if (isANumber && isBNumber) {
          return sortOrder === "asc" ? numA - numB : numB - numA;
        } else if (isANumber) {
          return sortOrder === "asc" ? -1 : 1;
        } else if (isBNumber) {
          return sortOrder === "asc" ? 1 : -1;
        } else {
          const valueALower = valueA !== null ? valueA.toLowerCase() : "";
          const valueBLower = valueB !== null ? valueB.toLowerCase() : "";
          return sortOrder === "asc" ? valueALower.localeCompare(valueBLower) : valueBLower.localeCompare(valueALower);
        }
      });
  }, [items, searchTerm, selectedTypes, selectedCreator, sortColumn, sortOrder]);

  const totalItems = filteredItems.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(totalPages, currentPage + 2);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [totalPages, currentPage]);

  const clearAllFilters = () => {
    setSearchTerm("");
    setSelectedCreator("");
    setSelectedCreator("");
    setCurrentPage(1);

    if (selectRef.current) {
      selectRef.current.clearValue();
    }

    // Update URL to reflect cleared filters
    const params = new URLSearchParams();
    navigate({ search: params.toString() });
  };

  return (
    <div className="justify-center items-start">
      <div className="container mx-auto p-3 rounded-lg shadow-lg bg-retrodp mt-2">
        <h1 className="text-4xl font-heading mb-1 text-center text-retrocream">Item Lookup</h1>
        <div className="space-between">
          <button onClick={() => setFiltersVisible(!filtersVisible)} className="p-1 rounded text-white text-xs hover:bg-retrobg bg-retrogray">
            {filtersVisible ? "-" : "+"}
          </button>{" "}
          <p className="inline text-xs">Total Items: {totalItems}</p>
        </div>
        {filtersVisible && (
          <div className="bg-retrobabypink p-2 rounded-lg text-center text-retropb mb-1">
            <div className="container mx-auto p-1 text-sm">
              <div className="flex flex-col md:flex-row items-center justify-between space-y-2 md:space-y-0 md:space-x-2">
                <input
                  type="text"
                  placeholder="Search by name..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="p-2 border rounded w-full flex-1"
                />
                <Select
                  ref={selectRef}
                  isMulti
                  options={typeOptions}
                  onChange={handleTypeChange}
                  placeholder="Item categories..."
                  className="w-full z-[36] flex-1 text-xs"
                  classNamePrefix="select"
                />
                <Select
                  onChange={handleCreatorChange}
                  value={selectedCreator ? { label: selectedCreator, value: selectedCreator } : { label: "UGC Creators...", value: "" }}
                  options={[
                    { label: "UGC Creators...", value: "" }, // Add an "All" option with an empty value
                    ...ugcCreators.map((creator) => ({
                      label: creator,
                      value: creator,
                    })),
                  ]}
                  className="text-retrogray text-xs m-1 z-30 w-full flex-1"
                  placeholder="UGC Creators..."
                />
                <button onClick={clearAllFilters} className="p-2 mx-1 rounded bg-retrodeeppink text-white text-xs hover:bg-retrogray w-full md:w-auto">
                  Clear All
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="overflow-auto h-[60vh]">
          <table className="tablelb w-full">
            <thead>
              <tr>
                <th onClick={() => handleSort("name")}>Name {sortColumn === "name" && (sortOrder === "asc" ? "↑" : "↓")}</th>
                <th onClick={() => handleSort("creatorName")}>Creator {sortColumn === "creatorName" && (sortOrder === "asc" ? "↑" : "↓")}</th>
                <th onClick={() => handleSort("type")}>Category {sortColumn === "type" && (sortOrder === "asc" ? "↑" : "↓")}</th>
                <th onClick={() => handleSort("marketPrice")}>MP {sortColumn === "marketPrice" && (sortOrder === "asc" ? "↑" : "↓")}</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr key={item.id}>
                  <td className="hover:text-retropink cursor-pointer" onClick={() => handleItemClick(item)}>
                    <img src={item.image} alt={item.name} className="h-5 w-5 inline" /> {item.name}
                  </td>
                  <td>{item.creatorName}</td>
                  <td>{item.type}</td>
                  <td>{item.marketPrice}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} startPage={startPage} endPage={endPage} />
      </div>
      {selectedItem && (
        <ItemModal
          item={selectedItem}
          onClose={handleCloseModal}
          goToPreviousItem={goToPreviousItem}
          goToNextItem={goToNextItem}
          currentIndex={currentIndex}
          itemsLength={filteredItems.length}
        />
      )}
    </div>
  );
};

export default UGCLookup;
