import beginnerquest1 from "../assets/images/Quests/beginnerquest1.png";
import beginnerquest2 from "../assets/images/Quests/beginnerquest2.png";

export const questsData = {
    quests: [
      // {
      //   id: ,
      //   name: "",
      //   status: "available/expired",
      //   npc: "",
      //   location: "",
      //   description: ``,
      //   items: ["None"],
      //   reward: "",
      //   otherinfo: "",
      //   image: [],
      // },
      {
        id: 48,
        name: "Jorge's Harvest Hustle",
        status: "expired",
        npc: "Jorge Grassias",
        location: "Harvest Hustle Map",
        description: `Jorge requires multiple crafted items to aid him with his competition. He asks you to craft 5 Harvest Hustle Haystacks and in return, you will receive 1 Harvest Hustle Hay Bale.<br/>
        After you bring him 5 Harvest Hustle Haystacks, he'll ask you to bring him 5 Harvest Hustle Wheelbarrows. The reward for this will be 1 Harvest Hustle Cart.<br/>
        Once you've completed the Wheelbarrows, he'll then ask you to bring 5 Harvest Hustle Rakes. The final reward will be the Harvest Hustle Western Wear (complete with hat!)<br/>
        You'll be able to keep and deposit all of these items into the Well for the leaderboard event also happening!`,
        items: ["35 Harvest Hay", "10 Silk Rope", "25 Copperite Screws", "50 Irontite Bars", "20 Whittlewood Logs", "75 Energy"],
        reward: "1 Harvest Hustle Hay Bale, 1 Harvest Hustle Cart & 1 Harvest Hustle Western Wear",
        otherinfo: "March 4th - March 9th 2025",
        image: [],
      },
      {
        id: 47,
        name: "Valentino, The Love Neutrino, Puts The Petal To The Metal!",
        status: "expired",
        npc: "Valentino",
        location: "Valentine Hut - Located Left of Fountain",
        description: `Valentino wants to present a bouquet of roses for Esme. He needs your help to gather the roses!
        <br/><i class="fa-solid fa-1" style="color: #9a7997;"></i> Speak to Valentino to acquire the quest. He will ask you to gather the ingredients to craft at the Ardor Augmentation Apparatus.
        <br/><i class="fa-solid fa-2" style="color: #9a7997;"></i> You will need 50 Heartbeets, 30 Honey, 50 Fertilizer and 120 Heartbeet Roses. 
        <br/><br/> ➡️ To get the Heartbeet Seeds, you can buy 1 per day in the store for 6,000 Coins each. You can also get seeds by participating in the Regular Heartbeet Hoedown (every 24h). You will need to plant these for heartbeets.
        <br/> ➡️ For Honey, you can get these from Apiaries (you will need to buy a Queen Bee in the Pet Shop and place on an apiary) or from the marketplace
        <br/> ➡️ For Fertilizer, you can buy these in the store for 20 Coins, or craft them at a Composter (see Animal Care in Skills) or from the marketplace (Total: 1,000 Coins)
        <br/> ➡️ And for the Roses, you can get these from the Premium Heartbeet Hoedown (every 8h) - for this you will need the Heartbeet Hoedown Token <img src="https://d31ss916pli4td.cloudfront.net/uploadedAssets/i/itm_heartbeettoken/2cafad5e-c834-4762-ba4f-00b7d6b797a3.png" alt="token" class="h-6 inline"/>
        <br/> <img src="https://d31ss916pli4td.cloudfront.net/uploadedAssets/i/itm_heartbeettoken/2cafad5e-c834-4762-ba4f-00b7d6b797a3.png" alt="token" class="h-6 inline"/> The Premium Ticket lasts for 21 Uses and each round lasts for 1 minute. You get both Cubes and Roses on a win (half andf half)
        <br/><br/><i class="fa-solid fa-3" style="color: #9a7997;"></i> Once you have acquired all the materials, craft the item at the Ardor Augmentation Apparatus
        <br/><i class="fa-solid fa-4" style="color: #9a7997;"></i> Return to Valentino with the complete item (which takes 3 hours and costs 10 energy and grants 500 Farming EXP)
        <br/><i class="fa-solid fa-5" style="color: #9a7997;"></i> Valentino will reward you with a special prize!`,
        items: ["50 Heartbeets", "30 Honey", "50 Fertilizer", "120 Heartbeet Roses"],
        reward: "50 Reputation & Loverboy Suit",
        otherinfo: "Feb 14 2025 - Feb 21 2025",
        image: ["https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/image_2025-02-14_100307546.png", "https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/image_2025-02-13_162739788.png", "https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/image_2025-02-13_162824304.png", "https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/image_2025-02-13_164828398.png"],
      },{
        id: 46,
        name: "Confections With Esme Bring Affections Your Way!",
        status: "expired",
        npc: "Esme",
        location: "Valentine Hut - Located Left of Fountain",
        description: `Esme wants to present a box of chocolates for Valentino, but she can't do it alone! She needs your help.
        <br/><i class="fa-solid fa-1" style="color: #9a7997;"></i> Speak to Esme to acquire the quest. She will ask you to gather the ingredients to craft at the Ardor Augmentation Apparatus.
        <br/><i class="fa-solid fa-2" style="color: #9a7997;"></i> You will need 50 Heartbeets, 30 Honey, 100 Cooking Mix and 120 Heartbeet Sugar Cubes. 
        <br/><br/> ➡️ To get the Heartbeet Seeds, you can buy 1 per day in the store for 6,000 Coins each. You can also get seeds by participating in the Regular Heartbeet Hoedown (every 24h). You will need to plant these for heartbeets.
        <br/> ➡️ For Honey, you can get these from Apiaries (you will need to buy a Queen Bee in the Pet Shop and place on an apiary) or from the marketplace
        <br/> ➡️ For Cooking Mix, you can purchase these from Buck's Galore for 10 Coins Each (Total: 1,000 Coins)
        <br/> ➡️ And for the Sugar Cubes, you can get these from the Premium Heartbeet Hoedown (every 8h) - for this you will need the Heartbeet Hoedown Token <img src="https://d31ss916pli4td.cloudfront.net/uploadedAssets/i/itm_heartbeettoken/2cafad5e-c834-4762-ba4f-00b7d6b797a3.png" alt="token" class="h-6 inline"/>
        <br/> <img src="https://d31ss916pli4td.cloudfront.net/uploadedAssets/i/itm_heartbeettoken/2cafad5e-c834-4762-ba4f-00b7d6b797a3.png" alt="token" class="h-6 inline"/> The Premium Ticket lasts for 21 Uses and each round lasts for 1 minute. You get both Cubes and Roses on a win (half andf half)
        <br/><br/><i class="fa-solid fa-3" style="color: #9a7997;"></i> Once you have acquired all the materials, craft the item at the Ardor Augmentation Apparatus
        <br/><i class="fa-solid fa-4" style="color: #9a7997;"></i> Return to Esme with the complete item (which takes 3 hours and costs 10 energy and grants 500 Cooking EXP)
        <br/><i class="fa-solid fa-5" style="color: #9a7997;"></i> Esme will reward you with a special prize!`,
        items: ["50 Heartbeets", "30 Honey", "100 Cooking Mix", "120 Heartbeet Sugar Cubes"],
        reward: "50 Reputation & Lovergirl Gown",
        otherinfo: "Feb 14 2025 - Feb 21 2025",
        image: ["https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/image_2025-02-14_100307546.png", "https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/image_2025-02-13_162739788.png", "https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/image_2025-02-13_162833106.png", "https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/image_2025-02-13_164828398.png"],
      },
      {
        id: 45,
        name: "2025 Lunar New Year!",
        status: "expired",
        npc: "Auntie May",
        location: "Terra Villa",
        description: `
        <i class="fa-solid fa-1" style="color: #9a7997;"></i>Talk to Auntie May every day to get Cabbabage Seeds. You get 1 if you're less than 2,000 Rep. And 24 if more than 2,000 Rep.
        <br/><i class="fa-solid fa-2" style="color: #9a7997;"></i> Plant the Cabbabage Seeds and harvest them for full grown Cabbabages.
        <br/><i class="fa-solid fa-3" style="color: #9a7997;"></i> When you're done growing them, talk to Auntie May!
        <br/><i class="fa-solid fa-4" style="color: #9a7997;"></i> She will then ask you to bring her dumplings. Each dumpling you provide, Auntie May will give you 1 Envelope.
        <br/><i class="fa-solid fa-5" style="color: #9a7997;"></i> If you're VIP, you can craft 6 Steamed Dumplings at a time in the Steamer! Otherwise, only 1 at a time.
        <br><img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/Auntie+May.png" class="w-48" />
        <br/><i class="fa-solid fa-6" style="color: #9a7997;"></i> When you're finished crafting the dumplings (30m each or 3h for the batch of 6), talk to Auntie May!
        <br/><i class="fa-solid fa-7" style="color: #9a7997;"></i> Auntie May will give you an envelope for each dumpling you give to her. Use them on youself and load up on prizes!
        <br/><br/>🐍 If you are above 2k Rep, you will receive a Gold Envelope.
        <br/>🐍 If you are below 2k Rep, you will receive a Red Envelope.
        <br/>🐍 Each envelope has prizes that you can win!

        <br/><br/>💌 Possible Prizes:<br/>Lunar New Year Snake Pet\n<br/>Lunar New Year Accessories
        <br/>🐉🐉2025 Lunar New Year Guanmao
        <br/>🐉🐉2025 Lunar New Year's Ornamental Hairstyle
        <br/>🐉🐉2025 Lunar New Year's Gorgeous Gown
        <br/>🐉🐉2025 Lunar New Year's Tangzhuang
        <br/>Lunar New Year Treats<br/>Lunar New Year Stoneshaping Boost Sculptures`,
        items: ["Cabbabage Seed, 1 Lunar Eggs, 1 Turkey Egg, 2 Seltsam Eggs"],
        reward: "Red or Gold Envelope with various prizes (see above)",
        otherinfo: "Jan 29, 2025 - Feb 5, 2025",
        image: ["https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/image_2025-01-29_014805160.png"],
      },
       {
        id: 44,
        name: "Search for Moki Snacks",
        status: "expired",
        npc: "Hantao",
        location: "Decor Store - In Front",
        description: `Moki is not a great driver and may have gotten into an accident. Need to locate all 11 snacks for Moki Pochi (Hantao). Locate the 11 Treats!\n
        <br/><i class="fa-solid fa-1" style="color: #9a7997;"></i> Land 119
        <br/><i class="fa-solid fa-2" style="color: #9a7997;"></i> Football Field Near Giraffe\n
        <br/><i class="fa-solid fa-3" style="color: #9a7997;"></i> Football Field Near the 30 Line\n
        <br/><i class="fa-solid fa-4" style="color: #9a7997;"></i> Carnival Behind the Bee Trailer\n
        <br/><i class="fa-solid fa-5" style="color: #9a7997;"></i> Carnival on the Car Track\n
        <br/><i class="fa-solid fa-6" style="color: #9a7997;"></i> One on the far left side of the Pier (Beach)\n
        <br/><i class="fa-solid fa-7" style="color: #9a7997;"></i> One in the bushes on the right (Beach)\n
        <br/><i class="fa-solid fa-8" style="color: #9a7997;"></i> Terra Villa next to the Theater (Left)\n
        <br/><i class="fa-solid fa-9" style="color: #9a7997;"></i> Terra Villa Left of Goose Bar\n
        <br/><i class="fa-solid fa-10" style="color: #9a7997;"></i> Guild Castle far top right corner (outside)\n
        <br/><i class="fa-solid fa-11" style="color: #9a7997;"></i> Ministry of Innovation \n\n
        <br/><br/>When you've found all of the Treats, go back to Hantao to finish the quest!
        `,
        items: ["None"],
        reward: "1/5 Chance to win a Moki NFT - 1/5 Chance to win a Moki UGC",
        otherinfo: "Nov 27 2024 - Nov 29 2024",
        image: [],
      },
      {
        id:  43,
        name: "Gabby's YGG Gourd",
        status: "expired",
        npc: "Gabby",
        location: "YGG Arena",
        description: `Gabby says: The Capricious Catering Co. we hired to cook our traditional spaghetti gourd pasta for the Summit Summation Supper fell through on us. Go figure! <br/>
        I need to grow gobs of YGGourds in the next 5 days to guarantee these gamers can gorge their gullets until they’re fully gratified!<br/>
        <br/>You'll be growing YGGourds for 5 days in a row to complete this quest!<br/><br/>
        <i class="fa-solid fa-1" style="color: #9a7997;"></i> Head to the bus stop that is left of the fountain and click on it! You will be instantly transported to the YGG Play Summit Arena<br/>
        <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/%7B5D259BAC-2404-4FF3-9396-00F3505ACCE0%7D.png" alt="Bus Stop" height="300"/><br/>
        <i class="fa-solid fa-2" style="color: #9a7997;"></i> You should get the quest upon entering the building. Grow 50 YGGourds per day to earn a PixTix/day<br/>
        <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/%7BE8BE7FD7-EBAF-4A08-BB91-5A4C062EBEA0%7D.png" alt="Bus Stop" height="300"/><br/>
        <i class="fa-solid fa-3" style="color: #9a7997;"></i> Walk around to each of the booths to collect seeds from different vendors. You collect every 24 hours from the last time you collected. <br/>
        <i class="fa-solid fa-4" style="color: #9a7997;"></i> When you have all 50 seeds, plant them in the YGG logo in the center and wait 5 minutes <br/>
        <i class="fa-solid fa-5" style="color: #9a7997;"></i> Harvest the YGGourds and return to Gabby with the YGGourds. He will give you 1 PixTix<br/>
        <i class="fa-solid fa-6" style="color: #9a7997;"></i> When all 5 days have completed, the quest will be marked as "Completed"<br/><br/>
        At the end, you will receive 2 additional PixTix for a total of 6 PixTix.<br/>`,
        items: ["325 Energy to plant, water and harvest the seeds each day"],
        reward: "6 PixTix",
        otherinfo: "Live Nov 18 2024 - Nov 23 2024",
        image: ["https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/%7BF13E7271-B6B7-41B9-A5DC-1284C8CA32A9%7D.png", "https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/%7BEA929F3B-9965-4BA9-A32A-4865E5063D28%7D.png"],
      },{
        id:  42,
        name: "$SUPER Star: Trades for Shades!",
        status: "expired",
        npc: "Ellio",
        location: "$SUPER Trailer",
        description: `Ellio asks you for 9 $SUPER stats that he'll trade for some $SUPER shades. <br/>
        <i class="fa-solid fa-1" style="color: #9a7997;"></i> Deposit 10 $SUPER into the game - The game will take 1 for Deposit fees<br/>
        <i class="fa-solid fa-2" style="color: #9a7997;"></i> You can purchase $SUPER from Uniswap, Binance, Coinbase or Direct from MM<br/>
        <i class="fa-solid fa-3" style="color: #9a7997;"></i> Head to the trailer top left of Terra Villa (right of the Windmill) <br/>
        <i class="fa-solid fa-4" style="color: #9a7997;"></i> Talk to Ellio who will give you the quest, and ask you to buy $SUPER stars<br/>
        <i class="fa-solid fa-5" style="color: #9a7997;"></i> Click the Star Trophy top right and purchase 9 $SUPER stats<br/>
        <i class="fa-solid fa-6" style="color: #9a7997;"></i> Talk to Ellio who will give you the quest, and ask you to buy $SUPER stars<br/>
        <i class="fa-solid fa-7" style="color: #9a7997;"></i> Head to the right side of the room, in the corner and buy the 9 $SUPER Stars<br/>
        <i class="fa-solid fa-8" style="color: #9a7997;"></i> Talk to Ellio again and he will reward you with the Shades<br/>`,
        items: ["9 $SUPER tokens - Gas very expensive"],
        reward: "$SUPER Slick Shades & Reputation",
        otherinfo: "Live Nov 6 2024 - Nov 8 2024",
        image: ["https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/%7B5F567BE0-C04B-4CAD-B971-83F4617BACEA%7D.png"],
      },
      {
        id: 41,
        name: "Binance Blockchain Week: Binance Swirl Twirl!",
        status: "expired",
        npc: "Rachel",
        location: "Binance Map",
        description: `Gather Ingredients for Rachel to create a special ice cream flavor! Enter the Binance Map to start the quest!<br/>
        <i class="fa-solid fa-1" style="color: #9a7997;"></i> You will need to collect milk, a cone, and both the yellow and black mixes from NPCs around the arena!<br/>
        - Kenny Li has the Yellow Scoop<br/>
        - Eowyn Chen has the Black Scoop<br/>
        - Alex Dreyfus has the Cone<br/>
        - Jason Bitbender has the Milk
        <br/><i class="fa-solid fa-2" style="color: #9a7997;"></i> Craft the Binance Swirl at the Ice Cream Machine top right of the Binance Map
        <br/><i class="fa-solid fa-3" style="color: #9a7997;"></i> Take the Ice Cream to Rachel at the bottom right, who will reward you with 3 PixTix.
        <br/><i class="fa-solid fa-4" style="color: #9a7997;"></i> Use the teleport behind Heidi to revisit Terra Villa, and select another prize from the Binance Trailer`,
        items: ["None"],
        reward: "3 PixTix to claim a prize from the trailer",
        otherinfo: "Live October 30th 2024",
        image: ["https://pixelore.s3.eu-central-003.backblazeb2.com/Event+Screenshots/%7B07D0F2E2-1F15-4ECA-BAEE-2A922562746D%7D.png", "https://pixelore.s3.eu-central-003.backblazeb2.com/Event+Screenshots/%7BF87FAE59-D511-469A-A58E-2BC1A1A5687D%7D.png"],
      },{
        id: 40,
        name: "Binance Blockchain Week: Block Party!",
        status: "expired",
        npc: "Richard Teng",
        location: "Binance Map",
        description: `Find 12 Binance Blocks scattered around the arena and bring them to Richard so he can connect them into a Binance Chain. Deliver the chain to Heidi to claim your PixTix!<br/>
        A chance to earn more PixTix!
        <br/><i class="fa-solid fa-1" style="color: #9a7997;"></i> Search inside and outside of the Binance Map for all 12 of the Binance Blocks. Two are outside by the tables on the left side. All the others are inside.
        <br/><i class="fa-solid fa-2" style="color: #9a7997;"></i> Talk to Richard again when you've found all 12. He will connect the Blocks and give you back a chain.
        <br/><i class="fa-solid fa-3" style="color: #9a7997;"></i> After you get the chain, talk to Heidi who will reward you with 3 PixTix.
        <br/><i class="fa-solid fa-4" style="color: #9a7997;"></i> Use the teleport behind Heidi to revisit Terra Villa, and select another prize from the Binance Trailer`,
        items: ["None"],
        reward: "3 PixTix to claim a prize from the trailer",
        otherinfo: "Live October 29th 2024",
        image: [],
      },
      {
        id: 39,
        name: "Binance Blockchain Week: Heidi's Swag Bag",
        status: "expired",
        npc: "Heidi",
        location: "Binance Map",
        description: `Collect the 7 different swag items from the sponsor booths and return to Heidi for some PixTix to use to purchase an item in Terra Villa. Also for a chance to win an NFT Land!<br/>
        <i class="fa-solid fa-1" style="color: #9a7997;"></i> Upon entering the Stadium, you will automatically receive the quest from Heidi to acquire the Swag. Talk to Vendors inside the Stadium
        <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/Vendors.png" alt="Vendors" width="700"/>
        -- Lily Liu will give you the Solana Sunny's<br/>
        -- JP Mullin will give you a Manta Bucket Hat<br/>
        -- JP Thor will give you a Thorchain Water Bottle<br/>
        -- Vincent Chok will give you a First Digital Business Card<br/>
        -- Dom Schiener will give you an Iota Shirt<br/>
        -- 9GAGCEO will give you a Memeland Keychain<br/>
        -- Regen Rene (who is more to the left side) will give you a Celo Coffee Mug<br/><br/>
        <i class="fa-solid fa-2" style="color: #9a7997;"></i> After you have collected all of these items, head back to Heidi. She will give you 3 TixPix to use at the Binance Trailer in Terra Villa<br/>
        <i class="fa-solid fa-3" style="color: #9a7997;"></i> Use the Teleport behind her and head to the Trailer. You'll be able to choose from 3 different items. Pick whichever one catches your eye! `,
        items: ["None"],
        reward: "3 PixTix to claim a prize from the trailer",
        otherinfo: "Live October 24th 2024",
        image: ["https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/%7B3F403049-E414-4500-8466-2E55EE56CEEF%7D.png", "https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/%7B77965427-6375-4F3D-A282-5FBBD5590593%7D.png"],
      },{
        id: 38,
        name: "Deranged Dental Exchange",
        status: "expired",
        npc: "Denis the Dentist",
        location: "Guild Hall Interior",
        description: `Denist the Dentist wants teeth for his collection. You need to craft an Ump-o-Lantern using Denis's supplies, and take it to Stu. <br/>
         <i class="fa-solid fa-1" style="color: #9a7997;"></i> You will need Teeth from the Maul-a-Skaul event, which you can play in the Guild Hall (go north from Denis). (See the Live Ops page for more info!)<br/>
         <i class="fa-solid fa-2" style="color: #9a7997;"></i> When you have enough teeth to buy all 4 items, purchase them.<br/>
         <i class="fa-solid fa-3" style="color: #9a7997;"></i> Head to Ouiji and craft your Ump-o-Lantern at his station<br/>
         <i class="fa-solid fa-4" style="color: #9a7997;"></i> After you have crafted one Ump-o-Lantern, go talk to Stu. He will give you an Ump-o-Thumper to smash open that Lantern<br/>
         <i class="fa-solid fa-5" style="color: #9a7997;"></i> Click on the Lantern from your inventory and place it on the table, then select the Thumper and smash it twice. You will get a costume! (I got the vamp!) <br/>
        `,
        items: ["None"],
        reward: "Free Ump-o-Thumper to open your first two Lanterns",
        otherinfo: "Live October 23rd 2024 - November 1st 2024",
        image: ["https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/%7B2047E0C4-B37F-42CB-8C4F-29E3396C037F%7D.png"],
      },
      {
        id: 37,
        name: "Barney's Bat-ch Catch",
        status: "expired",
        npc: "Barney",
        location: "Guild Hall Exterior - Barney will be located ouside",
        description: `Until Halloween, 3 New Bats will be available each day until 1pm PST ( 8PM UTC ). Collect all 27 bats for a secret surprise!<br/>For specific locations, check out the <a href="/game-wiki/events/summary" rel="noreferrer" target="_blank">Halloween Events page</a>. `,
        items: ["27 Bats"],
        reward: "Unknown atm",
        otherinfo: "Live October 23rd 2024 - November 1st 2024",
        image: [],
      },      
      {
        id: 36,
        name: "Reputable Banker",
        status: "available",
        npc: "Margaret",
        location: "Bank",
        description: `Purchasing Coins using $PIXEL activates and completes the quest! Any amount you purchase will work.<br/>
        Can only be completed once.`,
        items: ["None"],
        reward: "100 Rep for first purchase",
        otherinfo: "The quest isn't really a quest? I think ( ﾉ ﾟｰﾟ)ﾉ | Live Oct 15 2024",
        image: [],
      },
      {
        id: 35,
        name: "Winery? More Like Sublimery",
        status: "available",
        npc: "Winona",
        location: "Right of The Drunken Goose - Bottom Left of the Fountain",
        description: `<img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/%7B181ED1FF-05F3-4457-9503-E5F5B08FAF12%7D.png" alt="Winery Quest" width="450" height="450"/><br/>
        <i class="fa-solid fa-1" style="color: #9a7997;"></i> Click on Winona to start up the Winery Quest! She's going to request 24 Popberries, 10 Cooking Mix and 3 Honey to get started. <br/>
        <i class="fa-solid fa-2" style="color: #9a7997;"></i> You will need to get these from various places: <br/> ➡️ Popberries are From Farming <br/> ➡️ Cooking Mix from the Shop (The Bucks Galore) <br/> ➡️ Honey from an Apiary
        <br/>
        <i class="fa-solid fa-3" style="color: #9a7997;"></i> Once you've got the ingredients, click on Winona again. She will instruct you to craft a Bottle of Popberry Wine. This will take 1 Minute <br/>
        <i class="fa-solid fa-4" style="color: #9a7997;"></i> Talk to Winona again after you've acquired the wine (Conveniently you can also give her an already crafted one if you don't want to wait 1 Minute (●'◡'●) )<br/>`,
        items: ["24 Popberries, 10 Cooking Mix, 3 Honey"],
        reward: "190 Coins",
        otherinfo: "Introduction to Winery Quest | Live Oct 15 2024",
        image: [],
      },{
        id: 34,
        name: "The Apparel Heir Apparent",
        status: "available",
        npc: "Tex",
        location: "South of the Bank - Bottom Right of the Fountain",
        description: `<img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/%7BC9A6D322-792D-4390-85B7-AD2D7C0B874D%7D.png" alt="Textiler Quest" width="450" height="450"/><br/>
        <i class="fa-solid fa-1" style="color: #9a7997;"></i> Click on Tex to start up the Textiler Quest! He's going to request 6 Silk Fiber and 4 Silk Slimes to get started. <br/>
        <i class="fa-solid fa-2" style="color: #9a7997;"></i> You will need to get these from a Slug Hutch - There's a public one north east of Terra Villa. You get a Slug from the Pet Store and place it on a hutch, then collect every Hour! Or you can buy the ingredients.<br/>
        <i class="fa-solid fa-3" style="color: #9a7997;"></i> Once you've got the ingredients, click on Tex again. He will instruct you to craft a Silk Rope. This will take 1 Minute in the Textile Station in front of him <br/>
        <i class="fa-solid fa-4" style="color: #9a7997;"></i> Talk to Tex again after you've acquired the rope (Conveniently you can also give him an already crafted one if you don't want to wait 1 Minute (●'◡'●) )<br/>`,
        items: ["6 Silk Fiber (Level 0 Animal Care) & 4 Silk Slug Slime (Level 2 Animal Care)"],
        reward: "60 Coins",
        otherinfo: "Introduction to Textiler Quest | Live Oct 15 2024",
        image: [],
      },{
        id: 33,
        name: "Off To The Daily Grind",
        status: "available",
        npc: "Gill",
        location: "Bottom left of the Theater - Top left from the fountain",
        description: `<img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/%7B6FD7415C-6803-4719-A7E5-D29E4A65E1AC%7D.png" alt="Windmill Quest" width="450" height="450"/><br/>
        <i class="fa-solid fa-1" style="color: #9a7997;"></i> Click on Gill to start up the Windmill Quest! He's going to request 2 Grainbows to get started. <br/>
        <i class="fa-solid fa-2" style="color: #9a7997;"></i> You will need to get these by planting them - you need to be level 5, and you can get the seeds from the store.<br/>
        <i class="fa-solid fa-3" style="color: #9a7997;"></i> Once you've got the Grainbow, talk to Gill again. He'll ask you to make one Grainbow Flour in the Windmill. Open up the Windmill Behind Gill and select Grainbow Flour from the Recipes.<br/>
        <i class="fa-solid fa-4" style="color: #9a7997;"></i> In 3 Minutes, you should get your Grainbow Flour from the Windmill<br/>
        <i class="fa-solid fa-5" style="color: #9a7997;"></i> Talk to Gill again after (Conveniently you can also give him an already crafted one if you don't want to wait 3 Minutes (●'◡'●) )<br/>`,
        items: ["2 Grainbow (Level 5 Farming)"],
        reward: "20 Coins",
        otherinfo: "Introduction to Windmill Quest | Live Oct 15 2024",
        image: [],
      },{
        id: 32,
        name: "A Rocky Start",
        status: "available",
        npc: "Sandy",
        location: "Left of Pixels HQ - Top left of Fountain",
        description: `<img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/%7B4D73BC31-8D62-40E9-8E6E-39B0458E6128%7D.png" alt="Stoneshaping Quest" width="450" height="450"/><br/>
        <i class="fa-solid fa-1" style="color: #9a7997;"></i> Click on Sandy to start up the Stoneshaping Quest! She's going to request 2 Clayum Matrix to get started. <br/>
        <i class="fa-solid fa-2" style="color: #9a7997;"></i> You will need to get these from a Mine - you can get some by using the Mine on your Speck!<br/>
        <i class="fa-solid fa-3" style="color: #9a7997;"></i> Once you've got the Clayum Matrix, click on Sandy again. She will instruct you to craft a Clayum Powder. <br/>
        <i class="fa-solid fa-4" style="color: #9a7997;"></i> Use the log on the Stoneshaper behind Sandy (by clicking the log and clicking on the stoneshaper)<br/>
        <i class="fa-solid fa-5" style="color: #9a7997;"></i> Click on the Stoneshaper and select Clayum Powder from the Recipe Book. Click Create. This will take 1 Minute to Craft. <br/>
        <i class="fa-solid fa-6" style="color: #9a7997;"></i> Talk to Sandy again after you've acquired the powder (Conveniently you can also give her an already crafted one if you don't want to wait (●'◡'●) )<br/>
        `,
        items: ["2 Clayum Matrix, 1 Log"],
        reward: "20 Coins",
        otherinfo: "Introduction to Stoneshaping Quest | Live Oct 15 2024",
        image: [],
      },{
        id: 31,
        name: "That's so METAL!",
        status: "available",
        npc: "Smith",
        location: "Top right of Fountain - Right of Pixels HQ",
        description: `<img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/%7BF82E8407-75A8-403D-9BC0-7415999803D7%7D.png" alt="Metalworking Quest" width="450" height="450"/><br/>
        <i class="fa-solid fa-1" style="color: #9a7997;"></i> Click on Smith to start up the Metalworking Quest! He's going to request 3 Copperite Ore to get started. <br/>
        <i class="fa-solid fa-2" style="color: #9a7997;"></i> You will need to get these from a Mine - you can get some by using the Mine on your Speck!<br/>
        <i class="fa-solid fa-3" style="color: #9a7997;"></i> Once you've got the Copperite Ore, click on Smith again. He will instruct you to craft a Copperite Bar. <br/>
        <i class="fa-solid fa-4" style="color: #9a7997;"></i> Click on the Metalworking Bench to the left of Smith, and select Copperite Bar and Create. In 1.5 Minutes, you should get your Copperite Bar from the Bench<br/>
        <i class="fa-solid fa-5" style="color: #9a7997;"></i> Talk to Smith again after you've acquired the bar (Conveniently you can also give him an already crafted one if you don't want to wait 1.5 Minutes (●'◡'●) )<br/>
        `,
        items: ["3 Copperite Ore"],
        reward: "20 Coins",
        otherinfo: "Introduction to Metalworking Quest | Live Oct 15 2024",
        image: [],
      },{
        id: 30,
        name: "Shoulda, Wood-a, Coulda!",
        status: "available",
        npc: "Jack",
        location: "Left of Fountain",
        description: `<img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/%7BC40C6D3F-9E16-4FF6-BDB5-016A1E092B3F%7D.png" alt="Woodworking Quest" width="450" height="450"/><br/>
        <i class="fa-solid fa-1" style="color: #9a7997;"></i> Click on Jack to start up the Woodworking Quest! He's going to request 3 Logs to get started. <br/>
        <i class="fa-solid fa-2" style="color: #9a7997;"></i> You will need to get these from a Tree marked with spraypaint - You might even have some trees on your Speck!<br/>
        <i class="fa-solid fa-3" style="color: #9a7997;"></i> Once you've got the Logs, click on Jack again. He will instruct you to craft a Plank. <br/>
        <i class="fa-solid fa-4" style="color: #9a7997;"></i> Click on the Woodworking Bench to the right of Jack, and select Whitelwood Plank and Create. In 1.5 Minutes, you should get your Plank from the Bench<br/>
        <i class="fa-solid fa-5" style="color: #9a7997;"></i> Talk to Jack again after you've acquired the plank (Conveniently you can also give him an already crafted one if you don't want to wait 1.5 Minutes (●'◡'●) )<br/>`,
        items: ["3 Logs"],
        reward: "20 Coins",
        otherinfo: "Introduction to Woodworking | Live Oct 15 2024",
        image: [],
      },{
        id: 29,
        name: "Welcome to the Buck's Galore, Quality Goods from Ceiling to Floor!",
        status: "available",
        npc: "Buck",
        location: "Inside Buck's Galore (Right of Fountain)",
        description: `<img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/%7BB39C2FFF-2B0E-4967-9F5E-359F78FB98EA%7D.png" alt="Bucks Quest" width="450" height="450"/><br/>
        <i class="fa-solid fa-1" style="color: #9a7997;"></i> Click on Buck to trigger the quest. He will give you a 10 Coin store credit. <br/>
        <i class="fa-solid fa-2" style="color: #9a7997;"></i> Click on the counter to purchase a Mover using the 10 Coins!<br/>
        <i class="fa-solid fa-3" style="color: #9a7997;"></i> After you purchase the Mover, you will complete the quest. He says you can talk to Jack next! <br/>
        `,
        items: ["None"],
        reward: "None",
        otherinfo: "Introduction to the Shop | Live Oct 15 2024",
        image: [],
      },     
      {
        id: 28,
        name: "Barney's Bazaarn Position Decision",
        status: "expired",
        npc: "Barney, Tina",
        location: "Barney's Bazaarn",
        description: `<div class="leading-relaxed"><i class="fa-solid fa-1" style="color: #9a7997;"></i>. Head to the Bazaarn in the Northern parts of Terra Villa. Barney's in a predicament! He has a lot of jobs that need to be done.<br/>
        <i class="fa-solid fa-2" style="color: #9a7997;"></i>. Talk to Barney to get the quest and then pick a contract. You can only pick one! So choose wisely.<br/>
        <i class="fa-solid fa-message" style="color: #9a7997;"></i> For context: each contract determines what your future working for Barney will look like (your rewards and multipliers)<br/><br/>
        <table class="table1 bg-retrobg"><thead><tr><th></th><th>Picker Packer</th><th>Warehouse Warrior</th><th>Bazaarn Boss</th></tr></thead>
        <tbody>
        <tr><td>Gacha Tickets</td><td>5</td><td>10</td><td>20</td></tr>
        <tr><td>Outfit</td><td><img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/JumpsuitIcon03-export.png" alt="Jumpsuit1" width="20" height="20"/></td><td><img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/JumpsuitIcon02-export.png" alt="Jumpsuit2" width="20" height="20"/></td><td><img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Quest+Data/JumpsuitIcon03_(1)-export.png" alt="Jumpsuit3" width="20" height="20"/></td></tr>
        <tr><td>Reputation<br/>Points</td><td colSpan="3" class="text-center">Unknown (same rep will be for all)</td></tr>
        <tr><td title="Multiplier is for the event">$PIXEL<br/>Multiplier</td><td>Small</td><td>Bigger</td><td>Biggest</td></tr>
        <tr><td>Cost</td><td>20 $PIXEL</td><td>30 $PIXEL</td><td>40 $PIXEL</td></tr>
        </tbody></table>
        <br/><i class="fa-solid fa-3" style="color: #9a7997;"></i>. After you have decided on a contract, click on Barney again and he will allow you to pick from the three contracts. Remember you can only use ONE. If you purchase multiple or buy the wrong one, you're SOL. ＞﹏＜<br/>
        <i class="fa-solid fa-4" style="color: #9a7997;"></i>. Head to Tina who is up the stairs inside the Bazaarn and on the left. She will take the contract from you and give you your Bazaarn Tickets.<br/>
        <i class="fa-solid fa-5" style="color: #9a7997;"></i>. You are now an official worker for Barney's Bazaarn! Check out our <a href="/game-wiki/events" class="text-retropink hover:text-white">events page</a> to get more information when the Bazaarn is live!~</div><br/>
        Use your new Green Gacha Clams to roll the Gacha Machine and try your luck.<br/>
        This quest is completely optional and you can still participate in the event even if you don't purchase a contract!
        <img src="https://pixelore.s3.eu-central-003.backblazeb2.com/Event+Screenshots/image_2024-09-10_225724782.png" alt="gachamachine" width="150" height="150"/>`,
        items: ["None"],
        reward: "Depends on the Contract you pick (see table above)",
        otherinfo: "September 10th 2024 - September 11th 2024",
        image: [],
      },
      {
        id: 27,
        name: "Okx Fokx Box",
        status: "expired",
        npc: "Mr. Okayecks",
        location: "Basement of the Bank",
        description: `Head to the basement of the bank and chat with Mr. Okayecks. He will give you 5 Fokx Bokx to cath 5 Fokxes. Head Back to Terra Villa and start hunting for Fokxes around. When you get close, try to use the Fokx Box to catch it! There is RNG to catching them so don't worry if they run away. Just keep trying until you catch 5. When you have 5 Fokx in a Bokx, go back to the Bank's Basement and place the boxes on the pedestals in front of Mr. Okayecks.`,
        items: ["None"],
        reward: "1 OKX Snapback Wearable and a raffle drawing for a Fokx Pet (1/5)",
        otherinfo: "August 14 2024 - August 19 2024",
        image: [],
      },{
        id: 26,
        name: "Happy Birthday, Luke!",
        status: "expired",
        npc: "Boss, Luke",
        location: "The Buck's Galore",
        description: `Go to Boss in the Buck's Galore to start the quest and get the Funky Bday Cake Recipe. Purchase the Recipe from the Shop next to Boss, and then click it from your inventory and use it on yourself. 
        <br/>Now you know how to make a birthday cake. Go find a stove. and the Cake Recipe will be a Tier 1 Recipe. <br/>
        The recipe requires 12 Clay Matrix, 6 Grainbow Flour, 6 Honey, 3 Eggs, 1 Syrup and 1 Cooking Mix. For crafting, it takes 1 minute, it gives 1 Exp, and it costs 1 Energy to Craft.<br/>
        After it's completed, take the cake back to Boss. He will then give you a Surfboard to get to Luke. Go to the right hand side of the Guild Caste (where it says Surf Station, Place Board), place the board, and head to Luke's Private Island<br/>
        There will be a small green tent. Select the cake, and click on the tent. He will then take the cake, thank you for it and give you Luke's Robe`,
        items: ["10 $PIXEL"],
        reward: "Luke Express Surfboard, Luke's Robe",
        otherinfo: "August 9 2024 - August 10 2024",
        image: [],
      },
      {
        id: 25,
        name: "Page Perry’s PIXELS Pavilion Parley",
        status: "expired",
        npc: "Page Perry",
        location: "Pixel HQ",
        description: `This was a quest where you loaded into a maze to find an X! The Maze could be in the shape of any of the letters from the word PIXELS.`,
        items: [],
        reward: "400 Reputation & Pixels Maze Trophy",
        otherinfo: "July 23 2024",
        image: [],
      },
      {
        id: 24,
        name: "Guide to Guilds: 1, 2 & 3",
        status: "expired",
        npc: "Player_W3, Gabby & Luke",
        location: "Guild HQ",
        description: `A quest for the hunt of a Power Pixel in the dungeons! This quest was a scavenger hunt of various items.`,
        items: [],
        reward: "200 Reputation, Power Pixel, Fingerprint, and a Magnifying Glass",
        otherinfo: "July 17 2024",
        image: [],
      },
      {
        id: 23,
        name: "Shorelimade for Shorelime Shades",
        status: "expired",
        npc: "Heidi",
        location: "Beach",
        description: `Craft 6 Shorelimeade  for Heidi.`,
        items: [],
        reward: "350 Reputation, Shorelime Shades",
        otherinfo: "June 20 2024",
        image: [],
      },
      {
        id: 22,
        name: "Jihoz's Shake Break",
        status: "expired",
        npc: "Jihoz",
        location: "Carnival [CH1TV] and/or Top Right Terra Villa [CH2TV]",
        description: `Craft a Cotton Candy Milkshake for Jihoz.`,
        items: [],
        reward: "250 Reputation, 1 Fishing Net",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 21,
        name: "Karen's Latte of Gratitude",
        status: "expired",
        npc: "Karen",
        location: "Karen's House [CH1TV]",
        description: `Craft a Grumpkin Spiced Latte for Karen.`,
        items: [],
        reward: "Recipe for 12 Grumpkin Spiced Lattes",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 20,
        name: "Roamin' for Ronin!",
        status: "expired",
        npc: "Ron",
        location: "Near the Fountain [CH1TV]",
        description: `Craft 10 Ronin Chains using Ronin Blocks found around Terra Villa.`,
        items: [],
        reward: "100$BERRY per Ronin Chain",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 19,
        name: "Wine Not [Popberry to Muckchuck]!",
        status: "expired",
        npc: "Goose",
        location: "Drunken Goose",
        description: `Craft 24 Bottles of Each Wine:<br/>Quest 1: Popberry<br/>Quest 2: Butterbrew<br/>Quest 3: Grainshine<br>Quest 4: Grumpkin Wine<br>Quest 5: Scarrot Wine<br>Quest 6: Watermint Whiskey<br>Quest 7:Astracactus Tequila<br>Quest 8:Hotato Hotka<br>Quest 9:Muckchuck Mead`,
        items: [],
        reward: "2 Bottles + Coin Cost of 24 Bottles<br>Quest 1 had 50 Reputation<br>Quest 2 Had 250 Reputation",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 18,
        name: "Fuy Gieri's Barbequest 1-4!",
        status: "expired",
        npc: "Fuy Gieri",
        location: "Drunken Goose [CH1TV]",
        description: `Craft BBQ Sauces, and every meal from each grill [Land, Water and Space].`,
        items: [],
        reward: "75 Reputation each for Quests 2, 3 and 4",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 17,
        name: "The Buck's Galore!",
        status: "expired",
        npc: "Hazel",
        location: "The Buck's Galore [CH1TV]",
        description: `Hazel guides you through use of the shop and explains how to get to other NPCs.`,
        items: [],
        reward: "None",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 16,
        name: "Axeing for Help",
        status: "expired",
        npc: "Lumber Jill",
        location: "South of Terra Villa [CH1TV]",
        description: `Lumber Jill teaches you how to cut wood, and asks you to cut some wood for her..`,
        items: [],
        reward: "1 Axe, 1 Energy Drink and 100 Forestry Exp",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 15,
        name: "Sticks and Planks",
        status: "expired",
        npc: "Stan Stolan and Old Man Gurney",
        location: "South East Terra Villa [CH1TV]",
        description: `Stan Stolar asks you to craft 8 sticks, and then 1 stool for Old Man Gurney..`,
        items: [],
        reward: "20 Coins, 100 Woodworking Exp",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 14,
        name: "Cooking with Karen",
        status: "expired",
        npc: "Karen",
        location: "Karen's House [CH1TV]",
        description: `Karen asks you to cook a Popberry Pie.`,
        items: [],
        reward: "2 Eggs, 100 Cooking Exp",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 13,
        name: "Axeing for Help",
        status: "expired",
        npc: "Lumber Jill",
        location: "South of Terra Villa [CH1TV]",
        description: `Lumber Jill teaches you how to cut wood, and asks you to cut some wood for her..`,
        items: [],
        reward: "1 Axe, 1 Energy Drink and 100 Forestry Exp",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 12,
        name: "Honor Bound & Even More Boxes",
        status: "expired",
        npc: "Honor",
        location: "Post Office",
        description: `Honor teaches you how to decorate a speck after you acquire one.`,
        items: [],
        reward: "1 Lithe Bush, 1 Random Rock 1, 1 Symmetrical Bush, 1 Simple Bush, 1 Imposing Bush, 1 Simple Bush (Flowering)",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 11,
        name: "Sunshine & Grainbows / Flour Powder",
        status: "expired",
        npc: "Fitz",
        location: "North West of Terra Villa",
        description: `Fitz asks you to grow 4 grainbow seeds, and then asks you to turn them into flour.`,
        items: [],
        reward: "4 Grainbow Seeds, 100 Granger Exp",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 10,
        name: "$MOCA Public Sale Code Contest",
        status: "expired",
        npc: "Mo",
        location: "Mocaverse Club House",
        description: `Craft 3 Moca Stars for Mo.`,
        items: [],
        reward: "Chance to Win Mocaverse Whitelist.",
        otherinfo: "March 30 2024 - April 3 2024",
        image: [],
      },
      {
        id: 9,
        name: "Rescue the Seasonal Sip",
        status: "expired",
        npc: "Staff",
        location: "Carnival left of the Orange Grumpkin Machine[CH1TV]",
        description: `Harvest 12 Grumpkins for the Staff Member.`,
        items: [],
        reward: "100 Game Tickets",
        otherinfo: "October 1 - 31 2023 for Carnival",
        image: [],
      },
      {
        id: 8,
        name: "999 Shorelime Time!",
        status: "expired",
        npc: "Buck",
        location: "Terra Villa Beach",
        description: `Plant 999 Shorelimes within 24 hours!`,
        items: [],
        reward: "Shorelime Tiki",
        otherinfo: "July 26th 2023",
        image: [],
      },
      {
        id: 7,
        name: "Tastemakerz Scene Shakers!",
        status: "expired",
        npc: "Master Wabi-san, Coach, E",
        location: "West Terra Villa, Sauna and North West Terra Villa",
        description: `Deliver messages around to the different NPCs`,
        items: [],
        reward: "1 Tastemaster Burger Combo",
        otherinfo: "Was a teaser event for Tastemakerz - Lasted from April 7 2023 - May 14 2023",
        image: [],
      },
      {
        id: 6,
        name: "Steven & Todd's Bad Hare Day",
        status: "expired",
        npc: "Steven & Todd",
        location: "East and West Terra Villa",
        description: `Search for Easter Eggs all over Terra Villa and return them to Steven or Todd for a reward. Odd Eggs to Todd, Even eggs to Steven.`,
        items: [],
        reward: "$BERRY per egg",
        otherinfo: "April 4th 2023 - April 18th 2023",
        image: [],
      },
      {
        id: 5,
        name: "Luck of the Four-Leaf Clover",
        status: "expired",
        npc: "Margaret",
        location: "Terra Villa (old ch1)",
        description: `Bring 1 4-leaf clover to Margaret for a prize.`,
        items: [],
        reward: "Leprechaun Sculpture, and the ability to find pots of gold around on different Farm Maps which could be sold for 317 $BERRY each.",
        otherinfo: "Quest Lasted March 7 2023 - March 23 2023",
        image: [],
      },
      {
        id: 4,
        name: "Wine Not? Heartbeet Delivery Service",
        status: "expired",
        npc: "Goose",
        location: "The Drunken Goose",
        description: `Help Goose craft 24 Heartbeet Wines at Winona's newly-opened Winery.`,
        items: [],
        reward: "3,500 $BERRY, and an invite to the energy parties which had 240 Energy and 600 $BERRY in total",
        otherinfo: "Feb 7 2023 - Feb 21 2023",
        image: [],
      },
      {
        id: 3,
        name: "A Coinbase Christmas",
        status: "expired",
        npc: "Marcus the Mycelimancer",
        location: "Old South East Terra Villa Map",
        description: `Locate the NPC, receive a Coinbase gift box and open it up using one of the obelisks.`,
        items: [],
        reward:
          "Possible Rewards: Apiary (50), Elephant Topiary (450), Gnome1 (500), Gnome2 (500), Pond (500), Bush (1500), Iron Bar (1500). There were a total of 5000 Coinbase Boxes",
        otherinfo: "Dec 19 2022 - Dec 21 2022",
        image: [],
      },
      {
        id: 2,
        name: "Merry Merry Christmas",
        status: "expired",
        npc: "Santa Barney",
        location: "Fountain",
        description: `Santa Barney dropped all of his candy canes all over Terra Villa. He needs you to help him find them. Needed to find 19 Blue, 19 Red and 19 Green Candy Canes.`,
        items: [],
        reward: "2 Turkey Eggs, 1 Concrete Snowman 2022",
        otherinfo: "Dec 19 2022 - Dec 21 2022",
        image: [],
      },
      {
        id: 1,
        name: "Tanksgiving",
        status: "expired",
        npc: "Tom Goeble, Goose",
        location: "Thanksgiving Map",
        description: `Tom Goeble owed Goose, and he asks if you can repay Goose on your behalf.`,
        items: [],
        reward: "1 Turkey Egg, 1 Ornamental Fish Tank 2022",
        otherinfo: "Nov 23 2022 - Dec 1 2022",
        image: [],
      },{
        id: 0,
        name: "Basics with Barney",
        status: "available",
        npc: "Barney, Margaret",
        location: `Barney's Farm & Beginner Speck`,
        description: `
          I: Barney will ask you to plant a <i>Popberry Seed</i>. At each step, he is going to give you the essential tools.<br/>
          II: First put the popberry seed into the ground.<br/>
          III: Then he will give you a <i>Rusty Watering Can</i> to water the seed.<br/>
          IV: When it has been watered, Barney will provide you with a <i>Basic Shears</i>. After 10 seconds, you can clip that Popberry out of the ground.<br/>
          V: Next, he'll give you 6 more popberry seeds! Follow Steps II - IV again for all 6 of them.<br/>
          VI: After you've harvested all of those seeds, Barney will give you a <i>Basic Axe</i> to chop some trees. Take the Axe from your bag, pick a tree and start whacking it.<br/>
          VII: When you've finished cutting down a tree, talk to Barney again. He'll commend you and send you off to the house.<br/>
          VIII: Once in the house, talk to Margaret. Add one of the logs you cut down to the stove next to Margaret.<br/>
          IX: The stove will be lit, and Margaret will ask you to create one <i>Popberry Jam</i>. Popberry Jams are the longest part of the process as they take 2 minutes each<br>(╥﹏╥).<br/>
          X: When you're done, Barney and Margaret will send you on your way to your own Speck. Barney will give you 24 Popberry seeds, and you'll get a Beginner Infinifunnel Task for 5 Popberry Jams!<br/>
          XI: Use the knowledge you've learnt up to this point, plant your seeds, craft your Jam, and when you've submitted all 5 Jams, Barney will reach out again!<br/>
          XII: This time, Barney's back to give you a <i>Basic Pick</i>. Head outside of your house and use the Pick on the grey <i>Mine</i> outside until you get something from it<br/>
          XIII: You're now free to go wherever you want. Explore Terra Villa, talk to NPCs, visit other lands and players. Go have fun!<br/>
        `,
        items: ["None needed, everything is provided"],
        reward: `30 Coins`,
        otherinfo: `See more about your Speck <a href='/game-wiki/land-specks'>here</a>`,
        image: [beginnerquest1, beginnerquest2],
      }
    ],
  };