import axios from "axios";
import { categoryMapping } from "./mappings";

export const fetchUGC = async (setItems, setUGCCreators, setTypes) => {
  try {
    const [marketResponse, ugcResponse] = await Promise.all([
      axios.get("https://api.pixelore.wiki/api/market-data"),
      axios.get("https://api.pixelore.wiki/api/pixelugcs"),
    ]);

    const items = ugcResponse.data;
    const marketData = marketResponse.data;
    const marketPrices = marketData.prices;
    const marketCount = marketData.counts;

    const itemsData = items.map((item) => {
      const itemid = item.id;
      const marketPrice = marketPrices[itemid];
      const itemCount = marketCount[itemid];
      const formattedMarketPrice = marketPrice ? new Intl.NumberFormat().format(marketPrice) : "N/A";
      const uniqueCategoriesSet = new Set([]);
      if (item.notes?.type) {
        uniqueCategoriesSet.add(categoryMapping[item.notes.type] || item.notes.type);
      }

      uniqueCategoriesSet.add("UGC");
      uniqueCategoriesSet.delete("Decoration");

      const uniqueCategories = [...uniqueCategoriesSet].join(", ");
      const type = uniqueCategories;

      let width, height;
      if (item.placeObject?.sprite?.image) {
        width = item.placeObject?.sprite?.size?.width || item.placeObject?.physics?.size?.width;
        height = item.placeObject?.sprite?.size?.height || item.placeObject?.physics?.size?.height;
      }
      const image = item.image?.startsWith("cdn:/") ? item.image.replace("cdn:/", "https://d31ss916pli4td.cloudfront.net/") : item.image;

      return {
        id: itemid,
        image: image,
        gamedescription: item.description,
        name: item.name,
        type: type,
        marketPrice: formattedMarketPrice,
        marketPieces: itemCount,
        description: item.notes?.description,
        spritesheet: (() => {
          const image = item.placeObject?.sprite?.image || item.placeEntity?.sprite;
          return image?.startsWith("cdn:/") 
              ? image.replace("cdn:/", "https://d31ss916pli4td.cloudfront.net/") 
              : image;
      })(),
        sprite: {
          height: height,
          width: width,
        },
        creator: item.ugc?.creator,
        creatorName: item.ugc?.creatorname,
      };
    });

    setItems(itemsData);

    if (setTypes) {
      const uniqueTypes = new Set();
      itemsData.forEach((item) => {
        if (item.type) {
          item.type.split(",").forEach((type) => uniqueTypes.add(type.trim()));
        }
      });

      const sortedTypes = [...uniqueTypes].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));
      setTypes([...sortedTypes]);
    }

    if (setUGCCreators) {
      const uniqueCreators = new Set();
      itemsData.forEach((item) => {
        if (item.creatorName) {
          uniqueCreators.add(item.creatorName);
        }
      });

      const sortedCreators = [...uniqueCreators].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));

      setUGCCreators(sortedCreators);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
