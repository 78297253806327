const categoryMapping = {
  blueprintsKitsRecipes: "Industry",
  interiorDecorations: "Decoration",
  exteriorDecorations: "Decoration",
  decor: "Decoration",
  tools: "Tools",
  consumables: "Consumable",
  consumable: "Consumable",
  pets: "Pet Items",
  crafting: "Crafting",
  farming: "Farming",
  specialty: "Specialty",
  general: "General",
  "drink/food": "Consumable",
  industry: "Industry",
  utility: "Utility",
  wallpaper: "Wall Decor",
  bedcover: "Bedding",
  wearable: "Clothing",
  flooring: "Flooring",
  glowstick: "Glowstick",
  decoration: "Decoration",
  storage: "Utility",
  wardrobe: "Asset Skins",
  event: "Event Items",
  disc: "Discoverability",
  skins: "Asset Skins",
  winery: "Winery",
  miscellaneous: "Misc",
  spacebbq: "Galactic Grill",
  stoneshaping: "Stoneshaping",
  landbbq: "Barney's BBQ",
  cooking: "Cooking",
  waterbbq: "Submarine Sizzler",
  woodwork: "Woodworking",
  metalworking: "Metalworking",
  machine: "The Machine",
  windmill: "Windmill",
  industries: "Not Craftable",
  textile_mill: "Textiler",
  shake: "Milkshake Machine",
  compost: "Composter",
  juicer: "Shorelime Juicer",
  coffee: "Latte Machine",
  quantum_recombinator: "Quantum Recombinator",
  moca: "Mocaverse Clubhouse",
  moca2: "Mocaverse Crafting Table",
  gemcutting: "Gem Cutting (Not available yet)",
  sweater: "Pixmas Textiling",
  boost: "Industry Boosts",  
};

const currencyMapping = {
  cur_coins: "Coins",
  cur_pixel: "$PIXEL",
  cur_cyber: "$CYBER",
  cur_nana: "$NANA",
  cur_pepe: "$PEPE",
  cur_other: "$Other",
  cur_polygon_berry: "Polygon Berry",
  cur_wron: "$WRON",
  cur_ape: "$APE",
  cur_teeth: "Teeth",
  cur_chain: "PixTix",
  cur_mistletoe: "Red Mistletokens",
  cur_mistletoe_green: "Green Mistletokens",
  cur_mistletoe_white: "White Mistletokens",
  cur_guildtoken: "Guild Runes"
};

const itemsToExclude = [
  "itm_yawnionSeeds",
  "itm_joybeanSeeds",
  "itm_baycSeeds",
  "itm_abomSeeds",
  "itm_perfectPopberrySeeds",
  "itm_mushroomSeeds_01",
  "itm_mushroomSeeds_02",
  "itm_mushroomSeeds_03",
  "itm_goldenPopberrySeeds",
  "itm_60dayflower_seed",
  "ach_sluggery",
  "ach_slughutch_kit_02",
  "ach_slughutch_kit_03",
  "ach_slughutch_kit_04",
  "ach_slughutch_kit_05",
  "ach_pet_treats_12pack",
  "ach_Popberry_Pie_TEST",
  "ach_watertest",
];

const industryMap = {
  winery: "Winery",
  miscellaneous: "Misc",
  spacebbq: "Galactic Grill",
  stoneshaping: "Stoneshaping",
  landbbq: "Barney's BBQ",
  cooking: "Cooking",
  waterbbq: "Submarine Sizzler",
  woodwork: "Woodworking",
  metalworking: "Metalworking",
  machine: "The Machine",
  windmill: "Windmill",
  farming: "Windmill",
  industries: "Not Craftable",
  textile_mill: "Textiler",
  shake: "Milkshake Machine",
  compost: "Composter",
  juicer: "Shorelime Juicer",
  coffee: "Latte Machine",
  quantum_recombinator: "Quantum Recombinator",
  moca: "Mocaverse Clubhouse",
  moca2: "Mocaverse Crafting Table",
  gemcutting: "Gem Cutting (Not available yet)",
  cookboxing: "Cooking Boxes",
  mineboxing: "Mining Boxes",
  businessboxing: "Business Boxes",
  metalboxing: "Metalworking Boxes",
  stoneboxing: "Stoneshaping Boxes",
  forestboxing: "Forestry Boxes",
  animalboxing: "Animal Care Boxes",
  farmboxing: "Farming Boxes",
  woodboxing: "Woodworking Boxes",
  business: "Business",
  petcare: "Animal Care",
  lantern: "Misc",
  tutorialcooking: "Tutorial"
};

const entityMap = {
  Boosts: {
    ent_bstB01: { name: "Farming Ice Sculpture", image: "/images/industries/ent_bstB01.png" },
    ent_bstB02: { name: "Forestry Ice Sculpture", image: "/images/industries/ent_bstB02.png" },
    ent_bstB03: { name: "Mining Ice Sculpture", image: "/images/industries/ent_bstB03.png" },
    ent_bstB04: { name: "Coop Ice Sculpture", image: "/images/industries/ent_bstB04.png" },
    ent_bstB05: { name: "Slug Hutch Ice Sculpture", image: "/images/industries/ent_bstB05.png" },
    ent_bstB06: { name: "Apiary Ice Sculpture", image: "/images/industries/ent_bstB06.png" },
    ent_bstB07: { name: "Mill Ice Sculpture", image: "/images/industries/ent_bstB07.png" },
    ent_bstB08: { name: "Cooking Ice Sculpture", image: "/images/industries/ent_bstB08.png" },
    ent_bstB09: { name: "Woodworking Ice Sculpture", image: "/images/industries/ent_bstB09.png" },
    ent_bstB10: { name: "Stoneshaping Ice Sculpture", image: "/images/industries/ent_bstB10.png" },
    ent_bstB11: { name: "Metalworking Ice Sculpture", image: "/images/industries/ent_bstB11.png"},
    ent_bstB12: { name: "Winemaking Ice Sculpture", image: "/images/industries/ent_bstB12.png" },
    ent_bstB13: { name: "Textilemaking Ice Sculpture", image: "/images/industries/ent_bstB13.png" },
    ent_bstB14: { name: "BBQ Ice Sculpture", image: "/images/industries/ent_bstB14.png" },
    ent_bstB15: { name: "Golden Snake Sculpture", image: "/images/industries/ent_bstB15.png" },
    ent_bstB16: { name: "Red Snake Sculpture", image: "/images/industries/ent_bstB16.png" },
  },
  Other:{
    ent_saunaportal: {name: "Portal", image: "/images/industries/ent_saunaportal.png"},
    ent_barneys_barn_door: {name: "Barney's Door", image: "/images/industries/ent_barneys_barn_door.png"},
  },
  Untiered: {
    ent_windmill: { name: "Windmill", image: "/images/industries/ent_windmill.png" },
    ent_coop: { name: "Coop", image: "/images/industries/ent_coop.png" },
    ent_allcrops: { name: "Soil", image: "/images/industries/ent_allcrops.png" },
    ent_sluggery: { name: "Slug Hutch", image: "/images/industries/ent_sluggery.png" },
    ent_textile: { name: "Textile Mill", image: "/images/industries/ent_textile.png" },
    ent_apiary: { name: "Apiary", image: "/images/industries/ent_apiary.png" },
    ent_turkey: { name: "Turkey", image: "/images/industries/ent_turkey.png" },
    ent_cow_pickup: { name: "Cow", image: "/images/industries/ent_cow.png" },
    ent_sheep_01: { name: "Sheep", image: "/images/industries/ent_sheep.png" },
    ent_planter_soil: { name: "Indoor Planters", image: "/images/industries/ent_planter.png" },
    ent_waterbbq: { name: "Submarine Sizzler", image: "/images/industries/ent_waterbbq.png" },
    ent_landbbq: { name: "Barneys BBQ", image: "/images/industries/ent_landbbq.png" },
    ent_science: { name: "Potions Table", image: "/images/industries/ent_science.png"},
    ent_spacebbq: { name: "Galactic Grill", image: "/images/industries/ent_spacebbq.png" },
    ent_speicial_mine: { name: "Special Mine (Bog)", image:  "/images/industries/ent_speicial_mine.png"},
    ent_speicial_mine_2: { name: "Special Mine (Crystal)", image: "/images/industries/ent_speicial_mine_2.png"},
    ent_speicial_mine_3: { name: "Special Mine (Mystic)", image:  "/images/industries/ent_speicial_mine_3.png"},
    ent_speicial_mine_4: { name: "Special Mine (Sky)", image:  "/images/industries/ent_speicial_mine_4.png"},
    ent_speicial_mine_5: { name: "Special Mine (Steppes)", image:  "/images/industries/ent_speicial_mine_5.png"},
  },
  T1: {
    ent_mine_01: { name: "Mine", image: "/images/industries/ent_mine_01.png" },
    ent_treeLand1v1: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeLand1v2: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeLand1v3: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeWater1v1: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeWater1v2: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeWater1v3: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeSpace1v1: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeSpace1v2: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeSpace1v3: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_stove: { name: "Stove", image: "/images/industries/ent_stove.png" },
    ent_metalworking_01: { name: "Metalworking", image: "/images/industries/ent_metalworking_01.png" },
    ent_kiln_01: { name: "Stoneshaping", image: "/images/industries/ent_kiln_01.png" },
    ent_woodwork_01: { name: "WW Bench", image: "/images/industries/ent_woodwork_01.png" },
    ent_winery_01: { name: "Winery", image: "/images/industries/ent_winery_01.png" },
  },
  T2: {
    ent_mine_02: { name: "Mine", image: "/images/industries/ent_mine_02.png" },
    ent_treeLand2v1: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeLand2v2: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeLand2v3: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeWater2v1: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeWater2v2: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeWater2v3: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeSpace2v1: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeSpace2v2: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeSpace2v3: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_stove_02: { name: "Stove", image: "/images/industries/ent_stove_02.png" },
    ent_metalworking_02: { name: "Metalworking", image: "/images/industries/ent_metalworking_02.png" },
    ent_kiln_02: { name: "Stoneshaping", image: "/images/industries/ent_kiln_02.png" },
    ent_woodwork_02: { name: "WW Bench", image: "/images/industries/ent_woodwork_02.png" },
    ent_winery_02: { name: "Winery", image: "/images/industries/ent_winery_02.png" },
  },
  T3: {
    ent_mine_03: { name: "Mine", image: "/images/industries/ent_mine_03.png" },
    ent_treeLand3v1: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeLand3v2: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeLand3v3: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeWater3v1: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeWater3v2: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeWater3v3: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeSpace3v1: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeSpace3v2: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeSpace3v3: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_stove_03: { name: "Stove", image: "/images/industries/ent_stove_03.png" },
    ent_metalworking_03: { name: "Metalworking", image: "/images/industries/ent_metalworking_03.png" },
    ent_kiln_03: { name: "Stoneshaping", image: "/images/industries/ent_kiln_03.png" },
    ent_woodwork_03: { name: "WW Bench", image: "/images/industries/ent_woodwork_03.png" },
    ent_winery_03: { name: "Winery", image: "/images/industries/ent_winery_03.png" },
  },
  T4: {
    ent_mine_04: { name: "Mine", image: "/images/industries/ent_mine_04.png" },
    ent_treeLand4v1: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeLand4v2: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeLand4v3: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeWater4v1: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeWater4v2: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeWater4v3: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeSpace4v1: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeSpace4v2: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeSpace4v3: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_stove_04: { name: "Stove", image: "/images/industries/ent_stove_04.png" },
    ent_winery: { name: "Winery", image: "/images/industries/ent_winery.png" },
    ent_metalworking_04: { name: "Metalworking", image: "/images/industries/ent_metalworking_04.png" },
    ent_kiln_04: { name: "Stoneshaping", image: "/images/industries/ent_kiln_04.png" },
    ent_woodwork_04: { name: "WW Bench", image: "/images/industries/ent_woodwork_04.png" },
  },
  T5: {
    ent_mine_05: { name: "Mine", image: "/images/industries/ent_mine_05.png" },
    ent_treeLand5v1: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeLand5v2: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeLand5v3: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeWater5v1: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeWater5v2: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeWater5v3: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeSpace5v1: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeSpace5v2: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_treeSpace5v3: { name: "Tree", image: "/images/industries/ent_tree.png" },
    ent_stove_05: { name: "Stove", image: "/images/industries/ent_stove_05.png" },
    ent_metalworking_05: { name: "Metalworking", image: "/images/industries/ent_metalworking_05.png" },
    ent_kiln_05: { name: "Stoneshaping", image: "/images/industries/ent_kiln_05.png" },
    ent_woodwork_05: { name: "WW Bench", image: "/images/industries/ent_woodwork_05.png" },
  },
};

const nonCraftableItemIds = ["itm_runningShoe_basic", "itm_slughutch_kit_05", "itm_slughutch_kit_04", "itm_slughutch_kit_03", "itm_slughutch_kit_02", "itm_gem_01"];

export { categoryMapping, currencyMapping, itemsToExclude, industryMap, nonCraftableItemIds, entityMap };
