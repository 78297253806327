import React, { useState, useEffect } from "react";
import Pagination from "../components/Pagination";
import BackToTopGlobal from "../components/BackToTop2";

const PixelAssets = () => {
  const [assets, setAssets] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100; //Pagination
  const [selectedAsset, setSelectedAsset] = useState(null);

  useEffect(() => {
    // Fetch assets when the component mounts
    fetchPixelAssets();
  }, []);

  const handlePageChange = (newPage) => setCurrentPage(newPage);
  const handleImageClick = (asset) => setSelectedAsset(asset);
  const closeModal = () => setSelectedAsset(null);

  const fetchPixelAssets = async () => {
    try {
      const response = await fetch("https://api.pixelore.wiki/api/assets");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setAssets(data);
    } catch (error) {
      console.error("Failed to fetch Assets items", error);
    }
  };

  // Filter assets based on search term
  const filteredAssets = assets
    .filter((asset) => asset.image) // Ensure image exists
    .filter((asset) => asset.id.toLowerCase().includes(search.toLowerCase()));

  const totalItems = filteredAssets.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredAssets.slice(indexOfFirstItem, indexOfLastItem);
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(totalPages, currentPage + 2);

  return (
    <div className="container mx-auto p-6 rounded-lg shadow-lg bg-retrodp h-[78vh] mt-5">
      <div className="md:flex">
        <h1 className="text-5xl font-heading mb-6 text-center text-retrocream md:w-3/6">In-Game Assets</h1>
        {/* Search Bar */}
        <div className="ml-auto">
          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="mb-4 p-2 border rounded text-retropb ml-20 mr-10 w-60 md:w-4/5"
          />
        </div>
      </div>

      <div className="overflow-auto h-[60vh]">
      {/* Fixed height and scrollable */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 text-center p-2 overflow-visible">
        {currentItems.map((asset) => (
          <div key={asset.id} className="relative border p-4 rounded-lg bg-retrogray group">
            <img
              src={asset.image ? asset.image?.startsWith("cdn:/") ? asset.image.replace("cdn:/", "https://d31ss916pli4td.cloudfront.net/") : asset.image : ""}
              alt={asset.name || "Asset"}
              className="w-75 h-75 ml-auto mr-auto mt-auto cursor-pointer"
              onClick={() => handleImageClick(asset)} // Set the clicked image
            />
            <div className="mt-2">
              <div className="font-bold">{asset.name}</div>
              <div className="text-white truncate">{asset.id}</div>
              {asset.description && (
                <div className="absolute inset-x-0 top-full mt-1 bg-gray-800 bg-opacity-75 text-white flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                  <p>{asset.description}</p>
                </div>
              )}
            </div>
          </div>
        ))}
        <BackToTopGlobal/>
      </div>

      {/* Modal for displaying the full-size image */}
      {selectedAsset && (
        <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-75">
          <div className="relative">
            <img
              src={selectedAsset.image}
              alt={selectedAsset.name || "Asset"}
              className="w-auto h-auto max-w-full max-h-full mx-auto"
            />
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-white bg-red-500 hover:bg-red-600 rounded-full p-1 text-xs"
            >
              X
            </button>
            <div className=" text-sm mt-10 bg-retropb rounded-md p-1 text-center">Right Click and Open in New Tab to see the image fully</div>
          </div>
        </div>
      )}
    </div>
      <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} startPage={startPage} endPage={endPage} />
    </div>
  );
};

export default PixelAssets;
