import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { currencyMapping, industryMap } from "../utils/mappings";
import HistoricalPriceChart from "./HistoricalPriceChart";
import axios from "axios";

const ItemModal = ({ item, onClose, goToPreviousItem, goToNextItem, currentIndex, itemsLength }) => {
  //adds esc key to close Modal
  const [isFlipped, setIsFlipped] = useState(false);
  const [historicalData, setHistoricalData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Adds esc key to close Modal
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
      if (event.key === "ArrowLeft" && currentIndex > 0) {
        goToPreviousItem();
      }
      if (event.key === "ArrowRight" && currentIndex < itemsLength - 1) {
        goToNextItem();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose, goToPreviousItem, goToNextItem, currentIndex, itemsLength]);

  // Fetch historical data when the modal is flipped
  useEffect(() => {
    const fetchHistoricalData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`https://api.pixelore.wiki/api/items/${item.id}`);
        const historical = response?.data[0]?.prices || [];
        setHistoricalData(historical);
      } catch (error) {
        console.error("Error fetching historical data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (isFlipped) {
      fetchHistoricalData();
    }
  }, [isFlipped, item]);

  if (!item) return null; // If no item is selected, do not render the modal

  return (
    <div className="fixed inset-0 bg-retropb bg-opacity-60 flex justify-center items-center z-[200] text-white">
      <div className="bg-retropb p-6 rounded-lg shadow-lg w-full max-h-svh overflow-auto lg:max-w-[35%] md:max-h-[80svh] md:min-w-[30%] text-sm border border-2 border-white">
        {/* Top Section */}
        <div className="flex justify-between mb-4">
          <div className="relative inline-block group">
            <img src={item.image} alt={item.id} className="max-w-48 min-w-16 max-h-24 flex-none mr-12" />
            {item.spritesheet && item.type !== "UGC" && (
              <div className="absolute top-0 left-0 mt-2 p-2 bg-retropb border border-gray-300 z-10 hidden group-hover:block">
                <img
                  src={item.spritesheet}
                  alt="Spritesheet"
                  className="hidden sm:block md:max-w-[500px] md:max-h-[500px] lg:max-w-[900px] lg:max-h-[900px] mx-auto"
                />
              </div>
            )}
          </div>
          <div className="flex-1 text-right">
            <h2 className="text-2xl font-heading">{item.name}</h2>
            <p className="text-xs text-gray-600">{item.id}</p>
            <p className="text-xs text-gray-400">{item.gamedescription || "No Game Description Available"}</p>
          </div>
        </div>

        {/* Div for Informational Section */}
        <div className="flex-1 md:pr-4">
          {/* If Creator Exists (i.e. UGC) then display this portion */}
          {item.type.includes("UGC") && (
            <div className="text-xs border-2 border-white bg-retrobg p-2 rounded-lg mb-1">
              {" "}
              <>
                <div className="hidden md:block max-h-96 overflow-auto border-2 border-retrobg shadow p-1 mb-2">
                  <img src={item.spritesheet} alt="Spritesheet" className="hidden sm:block lg:max-w-[900px] lg:max-h-[900px] mx-auto" />
                  <br />
                </div>
                <div className="flex justify-between">
                  <div>
                    <span>
                      <i className="fa-solid fa-font-awesome fa-xs" style={{ color: "#E294E2" }} /> <strong> Item Type:</strong> {item.type} <br />
                    </span>
                    <span>
                      <strong>
                        <i className="fa-solid fa-palette fa-xs" style={{ color: "#E294E2" }} /> Creator :{" "}
                      </strong>
                    </span>
                    <Link to={`/lookup/players/${item.creator}`} target="_blank" rel="noreferrer">
                      {" "}
                      {item.creatorName}
                    </Link>{" "}
                  </div>
                  <div>
                    {" "}
                    H: {item.sprite.height} x W: {item.sprite.width}{" "}
                  </div>
                </div>
                <div>
                  <strong>
                    <i className="fa-solid fa-shop fa-xs" style={{ color: "#E294E2" }} /> Market Price:
                  </strong>{" "}
                  {item.marketPrice !== "N/A" ? item.marketPrice + " Coins" : "N/A"}
                </div>
              </>{" "}
            </div>
          )}
          {/* Historical Price Chart */}
          {isFlipped && (
            <div className="flex-1 bg-retrobg p-2 rounded-md border-2 border-white my-2">
              {loading ? (
                <div>Loading historical data...</div>
              ) : (
                <HistoricalPriceChart historicalPrices={historicalData} currentPrice={item.marketPrice} currentAmount={item.marketPieces} />
              )}
            </div>
          )}
          {/* If not UGC*/}
          {!item.type.includes("UGC") && !isFlipped && (
            <div className="mb-1 mt-2">
              <p className="text-sm text-red-200 mt-1 text-center">{item.limited ? "LIMITED ITEM --- Amount in Circulation: " + item.amount : ""}</p>

              {item.description && (
                <div className="text-xs border-2 border-white bg-retrobg p-2 rounded-lg mb-1">
                  <strong>
                    <i className="fa-solid fa-pen-nib fa-xs" style={{ color: "#E294E2" }} /> PixeLore Description:
                  </strong>{" "}
                  {item.description || "No PixeLore Description Available"}
                </div>
              )}

              <div className="text-xs border-2 border-white bg-retrobg p-2 rounded-lg">
                <div className="justify-between flex">
                  <span>
                    <i className="fa-solid fa-font-awesome fa-xs" style={{ color: "#E294E2" }} /> <strong> Item Type:</strong> {item.type}
                  </span>
                  <span>
                    {item.craftable !== "Not Craftable" && (
                      <p>
                        <strong>
                          <i className="fa-solid fa-industry" style={{ color: "#E294E2" }} /> Industry:
                        </strong>{" "}
                        {item.craftable} ( {item.tier !== "No Tier" ? "T" : ""}
                        {item.tier} )
                      </p>
                    )}
                  </span>
                </div>

                <div>
                  {item.energyValue !== "N/A" && (
                    <p>
                      <strong className="">
                        <i className="fa-solid fa-bolt fa-xs" style={{ color: "#E294E2" }} /> Edible Energy:
                      </strong>{" "}
                      {item.energyValue}
                    </p>
                  )}
                  <div>
                    <span>
                      <strong>
                        <i className="fa-solid fa-handshake fa-xs" style={{ color: "#E294E2" }} /> Tradable:
                      </strong>{" "}
                      {item.tradeable ? "Yes" : "No"}
                    </span>

                    {item.discpts && (
                      <div>
                        <strong>
                          <i class="fa-solid fa-cube fa-xs" style={{ color: "#E294E2" }} /> Discoverability Points:{" "}
                        </strong>
                        {item.discpts}
                      </div>
                    )}

                    {item.marketPrice !== "N/A" && (
                      <span>
                        <br />
                        <strong>
                          <i className="fa-solid fa-shop fa-xs" style={{ color: "#E294E2" }} /> Market Price:
                        </strong>{" "}
                        {item.marketPrice} Coins ({item.marketPieces ? item.marketPieces.toLocaleString() : 0} Available)
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <strong>
                    <i className="fa-solid fa-coins fa-xs" style={{ color: "#E294E2" }} /> Where to Buy:
                  </strong>
                  {item.purchaseable?.length > 0
                    ? item.purchaseable.map((store, index) => (
                        <span key={index}>
                          <br /> {store.store}:{" "}
                          <span className="ml-2">
                            Price: {store.buyPrice} x <img src={`/images/currencies/${store.currency}.png`} alt={store.currency} className="w-4 inline" />{" "}
                            {currencyMapping[store.currency] || store.currency}
                          </span>
                        </span>
                      ))
                    : item.marketPrice !== "N/A"
                    ? " Only Marketplace"
                    : " Cannot be bought"}
                </div>
              </div>

              {/* Recipes where item can be used in & Recipes that craft the item */}
              <div className="flex flex-col md:flex-row md:gap-2 mt-1">
                {/* Recipes for the Items */}
                <div className="flex-1 text-xs border-2 border-white bg-retrobg p-2 rounded-lg mb-1">
                  <div>
                    <p className="text-center">
                      <i className="fa-solid fa-chalkboard-user" style={{ color: "#E294E2" }} />
                      <strong className="text-lg"> Recipes</strong>
                    </p>
                  </div>
                  {!item.creator && item.recipes?.length > 0 ? (
                    <div>
                      {item.recipes.map((recipe, index) => (
                        <div className="p-1 bg-gray-300 opacity-50 rounded-lg mb-1 border-white border-1 text-retropb" key={index}>
                          <span className="text-base mt-1">
                            <strong>{recipe.name}</strong>
                          </span>
                          <br />
                          <strong>Crafting Cost:</strong> {new Intl.NumberFormat().format(recipe.totalCost)} Coins
                          <br />
                          <strong>Crafting Time:</strong> {recipe?.time || "N/A"} Minutes
                          <br />
                          <strong>Requires VIP?:</strong> {recipe?.requiredMemberships[0] === "vip" ? "Yes" : "No"}
                          <div className="ml-3">
                            <strong>Level:</strong> {recipe.level || "0"} {industryMap[recipe.expType] || recipe.expType}
                            <br />
                            <i className="fa-solid fa-bolt" style={{ color: "#E294E2" }} /> {recipe.energyCost} Energy | {recipe.exp} EXP
                            <br />
                          </div>
                          <ul>
                            <br />
                            <strong>Ingredients:</strong>
                            {recipe.requiredItems.map((reqItem, idx) => (
                              <li key={idx} className="ml-3 mylists">
                                <strong>{reqItem.name}</strong>: {reqItem.quantity} (Cost: {new Intl.NumberFormat().format(reqItem.totalCost)} Coins)
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  ) : (
                    "No Recipes Available for this item"
                  )}
                </div>
                {/* Recipes where the item is used in */}
                <div className="flex-1 text-xs border-2 border-white bg-retrobg p-2 rounded-lg mb-1 max-h-[35vh] overflow-auto">
                  <p className="text-center">
                    <i className="fa-solid fa-file" style={{ color: "#E294E2" }} />
                    <strong className="text-lg"> Used In</strong>
                  </p>
                  {item.uses.length > 0 ? (
                    <ul>
                      {/* Loop over `uses` and render each item */}
                      {item.uses.map((useItem, index) => (
                        <li key={index} className="bg-retropb p-1 rounded-md border border-white shadow-md shadow-retrogray text-xs">
                          {/* Render the image */}
                          <img src={useItem.achievementImage} alt={useItem.name} className="w-4 h-4 mr-2 inline" />

                          {/* Render the name */}
                          <span>{useItem.name}</span>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No crafting uses found for this item.</p>
                  )}
                </div>
              </div>
            </div>
          )}{" "}
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-between items-center mt-4">
          {/* Previous Button */}
          {typeof currentIndex === "number" && (
            <button
              onClick={goToPreviousItem}
              className={`px-2 py-1 rounded-lg hover:bg-retrodp ${currentIndex === 0 ? "opacity-50 cursor-not-allowed" : "bg-retropink"}`}
              disabled={currentIndex === 0} // Disable if on the first item
            >
              &larr; {/* Left Arrow */}
            </button>
          )}

          {/* Flip Button */}
          <button className="px-2 py-1 bg-retropink text-white rounded-lg hover:bg-retrodp" onClick={() => setIsFlipped((prev) => !prev)}>
            {isFlipped ? "Hide Market Data" : "Show Market Data"}
          </button>

          {/* Close Button */}
          <button onClick={onClose} className="px-2 py-1 bg-retropink text-white rounded-lg hover:bg-retrodp">
            Close
          </button>

          {/* Next Button */}
          {typeof currentIndex === "number" && (
            <button
              onClick={goToNextItem}
              className={`px-2 py-1 rounded-lg hover:bg-retrodp ${currentIndex === itemsLength - 1 ? "opacity-50 cursor-not-allowed" : "bg-retropink"}`}
              disabled={currentIndex === itemsLength - 1} // Disable if on the last item
            >
              &rarr; {/* Right Arrow */}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemModal;
