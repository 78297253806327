import { useEffect, useState, useMemo } from "react";
import BackToTopGlobal from "../components/BackToTop2";
import LoadingSpinner from "../components/LoadingSpinner";

const fieldMappings = {
  "trade.value": "Trade Value",
  cooldownMs: "Cooldown (MS)",
  uses: "Item Uses",
  categories: "Categories",
  description: "Description",
  itemUses: "New Recipe Uses",
  created: "Created",
  removed: "Removed",
  name: "Name Change",
  craftable: "Made Craftable",
  tradable: "Made tradable",
  "craftable.requiredTier": "Tier Change",
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const suffix = day % 10 === 1 && day !== 11 ? "st" : day % 10 === 2 && day !== 12 ? "nd" : day % 10 === 3 && day !== 13 ? "rd" : "th";
  return `${date.toLocaleDateString("en-US", { month: "short" })} ${day}${suffix}, ${date.getFullYear()}`;
};

const cleanFieldName = (field) => {
  const cleanField = field
    .replace(/\.\d+$/, "")
    .replace(/\.before$/, "")
    .replace(/\.after$/, "");

  const displayName = fieldMappings[cleanField] || cleanField;

  if (field.includes(".before")) {
    return `${displayName} (Before)`;
  }

  if (field.includes(".after")) {
    return `${displayName} (After)`;
  }

  return displayName;
};

export default function ChangeLog() {
  const [logs, setLogs] = useState(null);
  const [items, setItems] = useState(null);
  const [achievements, setAchievements] = useState(null);
  const [search, setSearch] = useState("");
  const [dateFilter, setDateFilter] = useState({ start: null, end: null });
  const [fieldFilter, setFieldFilter] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [logsRes, itemsRes, achievementsRes] = await Promise.all([
          fetch("https://api.pixelore.wiki/api/changelogs").then((res) => res.json()),
          fetch("https://api.pixelore.wiki/api/pixelitems").then((res) => res.json()),
          fetch("https://api.pixelore.wiki/api/pixelachievements").then((res) => res.json()),
        ]);

        setLogs(logsRes);
        setItems(Object.fromEntries(itemsRes.map((item) => [item.id, item.name])));
        setAchievements(Object.fromEntries(achievementsRes.map((ach) => [ach.id, ach.name])));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const filteredLogs = useMemo(() => {
    const flattenKeys = (obj, prefix = "") =>
      Object.entries(obj).flatMap(([key, value]) =>
        typeof value === "object" && value !== null
          ? flattenKeys(value, `${prefix}${key}.`) // Recurse for nested objects
          : `${prefix}${key}`
      );

    if (!logs || !items || !achievements) return [];

    const seenLogs = new Set();

    return logs
      .filter((log) => {
        const itemName = items[log.id] || achievements[log.id] || log.id;
        if (!itemName || itemName === "null") return false;

        const logKey = `${log.id}-${log.timestamp}`;
        if (seenLogs.has(logKey)) return false;
        seenLogs.add(logKey);

        const startDate = new Date(dateFilter.start);
        const endDate = new Date(dateFilter.end);
        const logDate = new Date(log.timestamp);

        const matchesDate = (!dateFilter.start || logDate >= startDate) && (!dateFilter.end || logDate <= endDate);

        const matchesSearch = itemName.toLowerCase().includes(search.toLowerCase()) || log.id.toLowerCase().includes(search.toLowerCase());

        const fieldPaths = flattenKeys(log.changes);
        const matchesField =
          !fieldFilter ||
          fieldPaths.some((fieldPath) => {
            const mappedField = fieldMappings[fieldPath] || fieldPath;
            return mappedField.toLowerCase().includes(fieldFilter.toLowerCase());
          });

        return matchesSearch && matchesDate && matchesField;
      })
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  }, [logs, items, achievements, search, dateFilter, fieldFilter]);

  const getChangeTypeLabel = (type) => {
    if (type === "achievement") {
      return "Recipe";
    }
    if (type === "item") {
      return "Item";
    }
    return "General";
  };

  const clearFilters = () => {
    setSearch("");
    setDateFilter({ start: null, end: null });
    setFieldFilter("");
  };

  if (!logs || !items || !achievements) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container mx-auto mt-5 p-6 rounded-lg shadow-lg bg-retrodp">
      <h1 className="text-6xl font-heading mb-6 text-center text-retroegg">Change Logs</h1>

      {/* Change Log Filters */}
      <div className="bg-retroegg p-3 m-2">
        <span className="font-teko text-retrobg">Change Log Filters</span>
        <div className="text-retrogray text-xs md:flex md:flex-row justify-between">
          <input
            type="text"
            placeholder="Search for an item..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="border p-2 mb-4 w-full md:w-1/4 text-xs"
          />

          <div className="flex space-x-4 mb-4">
            <input
              type="date"
              value={dateFilter.start || ""}
              onChange={(e) => setDateFilter((prev) => ({ ...prev, start: e.target.value }))}
              className="border p-2"
            />
            <span className="my-auto">to</span>
            <input
              type="date"
              value={dateFilter.end || ""}
              onChange={(e) => setDateFilter((prev) => ({ ...prev, end: e.target.value }))}
              className="border p-2"
            />
          </div>

          <select value={fieldFilter} onChange={(e) => setFieldFilter(e.target.value)} className="border p-2 mb-4 w-full md:w-1/4 text-xs">
            <option value="">All Fields</option>
            {Object.keys(fieldMappings).map((key) => (
              <option key={key} value={key}>
                {fieldMappings[key]}
              </option>
            ))}
          </select>

          <button onClick={clearFilters} className="w-fit h-fit p-2 bg-gray-200 hover:bg-retrocream font-semibold text-xs font-white rounded-lg">
            Clear Filters
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4 m-2">
        {filteredLogs.map((log) => {
          const changes = log.changes;
          const itemName = items[log.id] || achievements[log.id] || log.id;
          const changeTypeLabel = getChangeTypeLabel(log.type);

          return (
            <div key={log._id} className="bg-retroegg shadow-md rounded-lg p-3 border border-gray-200">
              <h2 className="text-base font-semibold font-chat text-retrobg">{itemName}</h2>
              <p className="text-gray-500 text-sm">
                <span className="font-semibold">{changeTypeLabel} Change</span> - Changed on: {formatDate(log.timestamp)}
              </p>

              <div className="mt-3 space-y-2">
                {Object.entries(changes).map(([field, value], index) => {
                  // Skip the 'id' field
                  if (field === "id") return null;

                  // Handle any 'newItems' array
                  if (value.newItems && Array.isArray(value.newItems)) {
                    // Determine the parent field (e.g., 'itemUses', 'categories')
                    const parentField = field;
                    const displayTitle = fieldMappings[parentField] || parentField;

                    return (
                      <div key={`${log._id}-${index}`} className="text-retropb p-4 border rounded-lg shadow-lg bg-gray-200 mb-4 text-sm">
                        <h2 className="text-lg font-teko font-semibold">{displayTitle}</h2>
                        {value.newItems.map((item, idx) => (
                          <div key={idx} className="flex items-center mb-2">
                            {typeof item === "object" && item.achievementImage ? (
                              <>
                                <img src={item.achievementImage} alt={item.name || "Achievement Image"} className="w-6 h-6 object-cover mr-2" />
                                <span className="text-blue-600 text-sm">{item.name}</span>
                              </>
                            ) : (
                              <span className="text-blue-600 text-sm">{item}</span>
                            )}
                          </div>
                        ))}
                      </div>
                    );
                  }

                  // Render other fields
                  const displayField = cleanFieldName(field);
                  return (
                    <div key={`${log._id}-${index}`} className="text-retropb p-4 border rounded-lg shadow-lg bg-gray-200 mb-4 text-sm">
                      <h2 className="text-lg font-teko font-semibold">{displayField}</h2>
                      <div>
                      {value?.before !== undefined && (
  <p>
    <span className="font-semibold">Before:</span> {value.before}
  </p>
)}
{value?.after !== undefined && (
  <p>
    <span className="font-semibold">After:</span> {value.after}
  </p>
)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      <BackToTopGlobal />
    </div>
  );
}
