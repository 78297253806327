import axios from "axios";
import { categoryMapping, nonCraftableItemIds, industryMap } from "./mappings";

export const fetchItems = async (setItems, setCraftables, setTypes, setUGCCreators, setObtainables) => {
  try {
    const [itemsResponse, marketResponse, achResponse] = await Promise.all([
      axios.get("https://api.pixelore.wiki/api/pixelitems"),
      axios.get("https://api.pixelore.wiki/api/market-data"),
      axios.get("https://api.pixelore.wiki/api/pixelachievements"),
    ]);

    const items = itemsResponse.data;
    const achievements = achResponse.data;
    const marketData = marketResponse.data;
    const marketPrices = marketData.prices;
    const marketCount = marketData.counts;
    const itemNameLookup = new Map(items.map((item) => [item.id, item.name]));

    const itemsData = items.map((item) => {
      let prices = [];
      if (Array.isArray(item.stores)) {
        prices = item.stores
          .filter((store) => store.buyPrice && store.currency && store.name)
          .map((store) => ({
            buyPrice: store.buyPrice,
            currency: store.currency,
            store: store.name,
          }));
      }

      const itemid = item.id;
      const result = items.find((dataItem) => dataItem.id === item.onUse?.plant?.fruit) || null;
      const marketPrice = marketPrices[itemid];
      const itemCount = marketCount[itemid];
      const formattedMarketPrice = marketPrice ? new Intl.NumberFormat().format(marketPrice) : "N/A";
      let energyValue = item.onUse?.energy?.value ? new Intl.NumberFormat().format(item.onUse.energy.value) : "N/A";
      const growTime = item.onUse?.plant?.growTime || null;
      const harvestEnergy = item.onUse?.plant?.harvestNRG || 0 || null;
      const plantEnergy = energyValue || 0;
      const plantingLvl = item?.requirements?.levels?.[0]?.level ?? 0;
      const harvestExp = item.onUse?.plant?.harvestXP || null;
      const categories = item.categories ? item.categories.map((category) => categoryMapping[category] || category) : [];
      const uniqueCategoriesSet = new Set(categories);
      if (item.notes?.type) {
        uniqueCategoriesSet.add(categoryMapping[item.notes.type] || item.notes.type);
      }
      if (item.ugc) {
        uniqueCategoriesSet.add("UGC"); // Add UGC
        uniqueCategoriesSet.delete("Decoration"); // Remove decoration if it exists
      }
      const uniqueCategories = [...uniqueCategoriesSet].join(", ");
      const type = uniqueCategories;

      let width, height;
      if (item.placeObject?.sprite?.image) {
        width = item.placeObject?.sprite?.size?.width || item.placeObject?.physics?.size?.width;
        height = item.placeObject?.sprite?.size?.height || item.placeObject?.physics?.size?.height;
      }

      let craftingCost = 0;
      let firstRecipeCostCalculated = false;
      let recipes = [];

      const craftingRecipes = {};
      achievements.forEach((achievement) => {
        if (achievement.type === "craftable" && achievement.craftable?.result?.items) {
          achievement.craftable.result.items.forEach((craftedItem) => {
            const itemId = craftedItem.id;
            if (!craftingRecipes[itemId]) {
              craftingRecipes[itemId] = [];
            }
            craftingRecipes[itemId].push(achievement);
          });
        }
      });

      // Fetch recipes for this item if any exist
      if (!nonCraftableItemIds.includes(itemid)) {
        if (craftingRecipes[itemid]) {
          craftingRecipes[itemid].forEach((achievement) => {
            const { requiredItems, requiredLevel, requiredSkill, minutesRequired, result, energy, type } = achievement.craftable;
            let recipeCost = 0;

            const recipeItems = requiredItems.map((reqItem) => {
              const reqItemName = itemNameLookup.get(reqItem.id) || "Unknown Item";
              const ingredientItem = items.find((itm) => itm.id === reqItem.id);
              const reqItemBuyPrice = ingredientItem?.stores?.[0]?.buyPrice || 0;

              // Use Infinity for invalid or zero values
              const marketPrice = marketPrices[reqItem.id] && marketPrices[reqItem.id] > 0 ? marketPrices[reqItem.id] : Infinity;

              // Get the minimum positive value
              const reqItemPrice = Math.min(reqItemBuyPrice > 1 ? reqItemBuyPrice : Infinity, marketPrice);
              const finalPrice = reqItemPrice === Infinity ? 0 : reqItemPrice;

              recipeCost += finalPrice * reqItem.quantity;

              return {
                name: reqItemName,
                quantity: reqItem.quantity,
                totalCost: finalPrice * reqItem.quantity,
              };
            });

            if (!firstRecipeCostCalculated) {
              craftingCost = recipeCost;
              firstRecipeCostCalculated = true;
            }

            recipes.push({
              id: achievement.id,
              name: achievement.name,
              requiredItems: recipeItems,
              energyCost: energy || 0,
              exp: result.exps ? result.exps[0]?.exp : 0,
              type: type ? type : result.exps ? result.exps[0]?.type : "N/A",
              expType: result.exps ? result.exps[0]?.type : "N/A",
              totalCost: recipeCost,
              level: requiredLevel,
              skill: requiredSkill,
              time: minutesRequired,
              requiredMemberships: achievement.craftable.requiredMemberships || [],
            });
          });
        }
      }

      const waysToObtain = [];

      // Check if `prices` is defined and not null
      if (prices) {
        // Extract store names from `prices` and add them to `waysToObtain`
        waysToObtain.push(...prices.map((store) => store.store));
      }

      // Check if `item.notes.obtain` is defined and is an array
      if (item.notes?.obtain && Array.isArray(item.notes.obtain)) {
        // Add methods from `item.notes.obtain` to `waysToObtain`
        waysToObtain.push(...item.notes.obtain);
      }

      // Remove duplicates if needed
      const uniqueWaysToObtain = [...new Set(waysToObtain)];

      const image = item.image?.startsWith("cdn:/") ? item.image.replace("cdn:/", "https://d31ss916pli4td.cloudfront.net/") : item.image;

      return {
        id: itemid,
        image: image,
        gamedescription: item.description,
        name: item.name,
        type: type,
        energyValue: energyValue,
        marketPrice: formattedMarketPrice,
        marketPieces: itemCount,
        energyPerCoin: energyValue / marketPrice || "N/A",
        coinPerEnergy: marketPrice / energyValue || "N/A",
        craftPerCoin: craftingCost > 0 ? energyValue / craftingCost : null,
        craftPerEnergy: craftingCost > 0 ? craftingCost / energyValue : null,
        craftingCost: craftingCost > 0 ? new Intl.NumberFormat().format(craftingCost) : "N/A",
        profit: marketPrice && craftingCost > 0 ? new Intl.NumberFormat().format(marketPrice - craftingCost) : "N/A",
        recipes: recipes,
        uses: item.uses || [],
        craftable:
          Array.isArray(item.achievements) && item.achievements.length > 0 && item.achievements[0]?.craftable?.type
            ? industryMap[item.achievements[0]?.craftable?.type] || item.achievements[0]?.craftable?.type
            : "Not Craftable",
        tier: item.tier ? item.tier : "No Tier",
        description: item.notes?.description,
        obtainable: uniqueWaysToObtain,
        tradeable: item.trade?.disableTrading ? "Cannot be traded" : "Tradeable",
        purchaseable: prices,
        limited: item.notes?.limited,
        amount: item.notes?.exist,
        discpts: item.notes?.discpts,
        spritesheet: item.placeObject?.sprite?.image || item.placeEntity?.sprite,
        sprite: {
          height: height,
          width: width,
        },
        creator: item.ugc?.creator,
        creatorName: item.ugc?.creatorname,
        level: recipes?.length > 0 ? recipes[0].level : "No Level",
        plantable: {
          plantEnergy: plantEnergy,
          harvestEnergy: harvestEnergy,
          exp: harvestExp,
          growthTime: growTime,
          result: result,
          level: plantingLvl,
        },
      };
    });

    setItems(itemsData);

    if (setCraftables) {
      const uniqueCraftables = new Set();
      itemsData.forEach((item) => {
        if (item.craftable) {
          uniqueCraftables.add(item.craftable);
        }
      });
      setCraftables([...uniqueCraftables]);
    }

    if (setTypes) {
      const uniqueTypes = new Set();
      itemsData.forEach((item) => {
        if (item.type) {
          item.type.split(",").forEach((type) => uniqueTypes.add(type.trim()));
        }
      });

      const sortedTypes = [...uniqueTypes].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));
      setTypes([...sortedTypes]);
    }

    if (setUGCCreators) {
      const uniqueCreators = new Set();
      itemsData.forEach((item) => {
        if (item.creatorName) {
          uniqueCreators.add(item.creatorName);
        }
      });

      const sortedCreators = [...uniqueCreators].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));

      setUGCCreators(sortedCreators);
    }

    if (setObtainables) {
      const obtainables = new Set();

      itemsData.forEach((item) => {
        if (item.obtainable && Array.isArray(item.obtainable)) {
          item.obtainable.forEach((store) => {
            if (typeof store === "string" && store.trim() !== "") {
              obtainables.add(store);
            }
          });
        }
      });

      const sortedObtainables = [...obtainables]
        .filter((item) => typeof item === "string" && item.trim() !== "") // Ensure all items are non-empty strings
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));

      setObtainables(sortedObtainables);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
