import React, { useState, useRef, useEffect, useCallback } from "react";
import axios from 'axios';
const overlay1 = 'https://Pixelore.s3.eu-central-003.backblazeb2.com/Twitter+Overlays/Pixels-Land-Twitter1_overlay.png';

export default function TwitterOverlay() {
  const [username, setUsername] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [uploadedPic, setUploadedPic] = useState(null);
  const [selectedOverlay, setSelectedOverlay] = useState(overlay1); // Default overlay
  const canvasRef = useRef(null);

  const fetchProfilePic = async () => {
    if (!username) return;
  
    try {
      const response = await axios.get("https://api.pixelore.wiki/api/ximage", {
        params: { username },
        responseType: "blob", // Important: Ensures we get raw image data
      });
  
      const imageUrl = URL.createObjectURL(response.data);
      setProfilePic(imageUrl);
      setUploadedPic(null);
    } catch (error) {
      console.error("Error fetching profile picture:", error);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setUploadedPic(e.target.result);
      reader.readAsDataURL(file);
      setProfilePic(null);
    }
  };

  const applyOverlay = useCallback(() => {
    const imgSrc = uploadedPic || profilePic;
    if (!imgSrc) return;
  
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
  
    const img = new Image();
    const overlay = new Image();
  
    img.crossOrigin = "anonymous";
    overlay.crossOrigin = "anonymous";
  
    img.src = imgSrc;
    overlay.src = selectedOverlay;
  
    Promise.all([
      new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      }),
      new Promise((resolve, reject) => {
        overlay.onload = resolve;
        overlay.onerror = reject;
      }),
    ])
      .then(() => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        ctx.drawImage(overlay, 0, 0, img.width, img.height);
      })
      .catch((err) => console.error("Error loading images:", err));
  }, [uploadedPic, profilePic, selectedOverlay]);
  

  const downloadImage = () => {
    const canvas = canvasRef.current;
    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = "twitter-overlay.png";
    link.click();
  };

  // Trigger overlay application right after fetching the profile picture
  useEffect(() => {
  if (profilePic || uploadedPic) {
    applyOverlay();
  }
}, [profilePic, uploadedPic, selectedOverlay, applyOverlay]); // Trigger whenever profilePic, uploadedPic, or selectedOverlay changes

  return (
    <div className="container mx-auto p-5 rounded-lg shadow-lg bg-retrodp w-full md:max-w-[60%] h-full md:min-h-[75vh] mt-5 flex flex-col items-center text-center">
      <div className="bg-retropb w-full h-fit border rounded-lg border-retropb shadow-sm p-2 m-1">        
      <h1 className="text-4xl font-heading mb-4 text-retrocream">Pixels Twitter Overlays</h1>
      ----------------------------------------
      <div className="md:flex md:flex-row md:justify-around">
        <div>
          {/* Instructions */}
          <p className="mb-4 text-sm text-retrocream">Enter your Twitter username to fetch your profile picture, or upload your own image.</p>

          <div className="flex flex-col items-center gap-4 w-full max-w-md">
            <div className="flex justify-evenly">
              {/* Username Input */}
              <input
                type="text"
                placeholder="Enter Twitter username..."
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className=" mx-1 p-2 border border-gray-300 rounded w-full text-retropb text-sm"
              />

              <span className="text-md mx-3">OR</span>

              {/* File Upload */}
              <input type="file" accept="image/*" onChange={handleFileUpload} className="w-full text-xs my-auto" />
            </div>
            <button onClick={fetchProfilePic} className="mx-1 px-4 py-2 rounded w-full bg-retrogray border-retropurple text-sm border-2 hover:bg-retrobg">
              Get Profile Picture
            </button>

            {/* Overlay Selector */}
            <p className="mt-4 text-lg text-retrocream">Select an overlay:</p>
            <div className="grid grid-cols-3 gap-4 w-full">
              {[
                { label: "Pixels Land", value: "https://Pixelore.s3.eu-central-003.backblazeb2.com/Twitter+Overlays/Pixels-Land-Twitter1_overlay.png" },
                { label: "Pixels Water", value: "https://Pixelore.s3.eu-central-003.backblazeb2.com/Twitter+Overlays/pixelsWaterTwitterOverlay.png"},
                { label: "Pixels Space", value: "https://Pixelore.s3.eu-central-003.backblazeb2.com/Twitter+Overlays/Pixels-Purple-Twitter2_overlay.png" },
                { label: "Pixels Space II", value: "https://Pixelore.s3.eu-central-003.backblazeb2.com/Twitter+Overlays/pixels-space-twitter-overlay.png" },
                { label: "Pixels Dark", value: "https://Pixelore.s3.eu-central-003.backblazeb2.com/Twitter+Overlays/Pixels-Land-Twitter_overlay.png" },
                { label: "Pixels Otherspeck", value: "https://Pixelore.s3.eu-central-003.backblazeb2.com/Twitter+Overlays/Pixels-Otherspeck-Twitter_overlay.png"},
              ].map((overlay, index) => (
                <div key={index} className="flex flex-col items-center cursor-pointer" onClick={() => setSelectedOverlay(overlay.value)}>
                  <img src={overlay.value} alt={overlay.label} className="w-16 h-16 object-cover rounded" />
                  <p className="text-xs text-retrocream">{overlay.label}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="hidden md:block w-[1px] bg-gray-700"></div>

        <div>
          {/* Canvas */}
          <canvas ref={canvasRef} className="border border-gray-300 rounded mt-4 mx-auto w-48"></canvas>

          {/* Download Button */}
          {(profilePic || uploadedPic) && (
            <button onClick={downloadImage} className="px-4 py-2 rounded w-full mt-4 text-sm bg-retrobg hover:bg-retrocream hover:text-retropb">
              Download Image
            </button>
          )}
        </div>
      </div>
      
      </div>
    </div>
  );
}
